import axios from "axios";
import localforage from "localforage";
import { currentDatetime } from "@/utils/datetime-utils";

export default class LabelReplacementOperationsRepository {

	constructor () {
		this.repositoryNamespace = "LABEL_REPLACEMENT_OPERATIONS"
	}

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(labelReplacementOperations => labelReplacementOperations ?? [])
	}

	async create (labelReplacementOperationData) {
		let labelReplacementOperation = {
			old_uuid: labelReplacementOperationData.old_uuid,
			new_uuid: labelReplacementOperationData.new_uuid,
			synced_at:  null,
			created_at: currentDatetime(),
		}

		if (navigator.onLine) {
			let requestParams = {
				old_uuid: labelReplacementOperationData.old_uuid,
				new_uuid: labelReplacementOperationData.new_uuid,
			}

			try {
				await axios.post("/label-replacement-operations/create", requestParams)
				labelReplacementOperation.synced_at = currentDatetime()
			} catch (error) {
				// Some errors are handled with an interceptor
			}
		}

		let existingLabelReplacementOperations = await localforage.getItem(this.repositoryNamespace) ?? []
		existingLabelReplacementOperations.push(labelReplacementOperation)

		return localforage.setItem(this.repositoryNamespace, existingLabelReplacementOperations)
	}

	async syncAll () {
		let existingLabelReplacementOperations = await localforage.getItem(this.repositoryNamespace) ?? []

		for (let i = 0; i < existingLabelReplacementOperations.length; i++) {
			let labelReplacementOperation = existingLabelReplacementOperations[i]

			if (navigator.onLine && labelReplacementOperation.synced_at == null) {
				let requestParams = {
					old_uuid: labelReplacementOperation.old_uuid,
					new_uuid: labelReplacementOperation.new_uuid,
				}

				try {
					await axios.post("/label-replacement-operations/create", requestParams)
					existingLabelReplacementOperations[i].synced_at = currentDatetime()
				} catch (error) {
					// Some errors are handled with an interceptor
				}
			}
		}

		return localforage.setItem(this.repositoryNamespace, existingLabelReplacementOperations)
	}

	// --------------------------------------------------

	async deleteAll () {
		return localforage.setItem(this.repositoryNamespace, [])
	}
}