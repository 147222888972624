import axios from 'axios'
import localforage from "localforage";

export default class FireClassesRepository {

	constructor () {
		this.repositoryNamespace = "FIRE_CLASSES"
	}

	// --------------------------------------------------

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(fireClasses => fireClasses ?? [])
	}

	async replaceAll (fireClasses) {
		return localforage.setItem(this.repositoryNamespace, fireClasses)
	}

	async replaceAllFromRemote () {
		try {
			let fireClasses = await axios.get("/fire-classes").then(response => response.data)
			return this.replaceAll(fireClasses)
		} catch (error) {
			// Some errors are handled with an interceptor
		}

		return this.getAll()
	}

	// --------------------------------------------------

	async deleteAll () {
		let keys = await localforage.keysStartingWith(this.repositoryNamespace)

		return Promise.all(keys.map(key => localforage.setItem(key, [])))
	}
}