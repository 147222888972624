<template>
	<div id="item-deadlines-index-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Scadenze
			</h1>
			<!-- <div class="my-0 ms-auto">
				<button type="button" class="btn btn-primary" @click="updateDeadlinesByRegistry()">
					Aggiorna
				</button>
			</div> -->
		</div>

		<input type="date"
			id="selected-date-input"
			class="form-control bg-secondary text-white text-center mb-3"
			v-model="selectedDate"
			@change="updateDeadlinesByRegistry()"
			required>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="card mb-3" v-if="annotations.length > 0">
				<div class="card-body p-0">
					<div class="accordion" id="accordionAnnotations">
						<div class="accordion-item">
							<h2 class="accordion-header">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAccordion" aria-expanded="true" aria-controls="collapseAccordion" v-on:click="toggleAccordion">
									<b>SEGNALAZIONI</b>
								</button>
							</h2>
							<div id="collapseAccordion" class="accordion-collapse collapse" data-bs-parent="#accordionAnnotations">
								<div class="accordion-body p-0 py-1">
									<div class="table-responsive">
										<table class="table table-bordered table-vmiddle mb-0">
											<thead>
												<tr>
													<th>Anagrafica</th>
													<th>Segnalazione</th>
													<th>Oggetto</th>
													<th class="text-nowrap text-center" width="1%">Priorità</th>
													<th class="text-nowrap text-center" width="1%">Data</th>
													<th class="text-nowrap text-center" width="1%">Azioni</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="annotation in annotations" :key="annotation.id">
													<td>
														<div v-if="annotation.registry">
															{{ annotation.registry.name }}
														</div>
														<div class="text-muted" v-if="annotation.registry_location">
															{{ annotation.registry_location.address }}
															<br>
															{{ annotation.registry_location.zip }}
															{{ annotation.registry_location.city }}
															({{ annotation.registry_location.province_code }})
														</div>
													</td>
													<td>
														<strong>{{ annotation.title }}</strong>
														<br>
														{{ annotation.description }}
													</td>
													<td>
														<div v-if="annotation.item">
															{{ annotation.item.registration_number }}
															<div class="text-muted">
																{{ annotation.item.object_type.name }}
																<br>
																{{ annotation.item.object_features.map(feature => feature.name ).join(", ") }}
																<br>
																{{ annotation.item.fire_class }}
																<br>
																{{ annotation.item.date }}
															</div>
														</div>
													</td>
													<td class="text-nowrap text-center">
														{{ annotation.priority }}
													</td>
													<td class="text-nowrap text-center">
														{{ formatDate(annotation.date) }}
													</td>
													<td class="text-nowrap text-center">
														<button type="button" class="btn btn-sm btn-secondary" title="Segna come completato" @click="markAsCompleted(annotation)" v-if="!annotation.completed_at">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="deadlinesByRegistry.length == 0">
				<div class="card mb-3">
					<div class="card-body">
						Nessuna scadenza programmata per la data selezionata.
					</div>
				</div>
			</div>
			<div v-else>
				<button class="btn btn-primary w-100 mb-3" type="button" @click="startQrScanner()" v-show="!showQrScanner">
					Abilita registrazione intervento
				</button>

				<div class="card mb-3" v-show="showQrScanner">
					<div class="card-body">
						<h4 class="card-title">
							Scansiona codice
						</h4>
						<canvas id="qr-scanner-canvas" style="border: solid 1px black; width: 100%;"></canvas>
					</div>
				</div>

				<div v-if="scannedCode">
					<div class="card border-danger mb-3" v-if="deadlinesForScannedCode.length == 0">
						<div class="card-body">
							Attenzione, nessuna scadenza trovata per il codice scansionato.
						</div>
						<div class="card-footer">
							<pre class="mb-0">Codice: {{ scannedCode }}</pre>
						</div>
					</div>

					<div v-for="registry in deadlinesForScannedCode" :key="registry.id">
						<div class="card border-success mb-3" v-for="registryLocation in registry.registry_locations" :key="registryLocation.id">
							<div class="card-body bg-success text-white">
								<div>
									{{ registry.name }}
								</div>
								<div class="">
									{{ registryLocation.address }},
									{{ registryLocation.city }}
									<span v-if="registryLocation.province_code">
										({{ registryLocation.province_code }})
									</span>
								</div>
							</div>
							<div class="table-responsive">
								<table class="table table-bordered table-vmiddle mb-0">
									<tbody>
										<template v-for="item in registryLocation.items">
											<template v-for="itemDeadline in item.item_deadlines">
												<tr :key="itemDeadline.id">
													<td class="text-nowrap">
														<span v-if="item.object_type">
															{{ item.object_type.name }}
														</span>
														<strong>{{ item.registration_number }}</strong>
													</td>
													<td class="text-center text-nowrap" width="1%">
														{{ item.object_features.map(feature => feature.name ).join(", ") }}
													</td>
													<td class="text-right text-nowrap" width="1%">
														{{ item.quantity }} Kg
													</td>
													<td class="text-center text-nowrap" width="1%">
														<span v-if="itemDeadline.deadline_rule && itemDeadline.deadline_rule.deadline_type">
															{{ itemDeadline.deadline_rule.deadline_type.name }}
														</span>
													</td>
													<td class="text-nowrap" width="1%">
														{{ item.location }}
													</td>
													<td class="text-center text-nowrap" width="1%">
														{{ itemDeadline.date.split("-").reverse().join("/") }}
													</td>
													<td class="text-center text-nowrap py-1 px-1" width="1%">
														<button type="button" class="btn btn-secondary" @click="recordMaintenanceOnItemDeadline(registry, registryLocation, item, itemDeadline)" v-if="itemDeadline.completed_at === null && !hasMaintenanceForDeadline(itemDeadline) && canRecordMaintenanceForItem(item)">
															Registra intevento
														</button>
														<button type="button" class="btn btn-warning" disabled v-else-if="hasMaintenanceToBeSyncedForDeadline(itemDeadline)">
															Da sincronizzare
														</button>
														<button type="button" class="btn btn-success" disabled v-else-if="hasSyncedMaintenanceForDeadline(itemDeadline)">
															Sincronizzato
														</button>
													</td>
												</tr>
											</template>
										</template>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div class="mb-3" style="display: flex; justify-content: space-evenly;">
						<div>
							&#x2053;
						</div>
						<div>
							&#x2053;
						</div>
						<div>
							&#x2053;
						</div>
						<div>
							&#x2053;
						</div>
						<div>
							&#x2053;
						</div>
					</div>
				</div>

				<div v-for="registry in deadlinesByRegistry" :key="registry.id">
					<div class="card mb-3" v-for="registryLocation in registry.registry_locations" :key="registryLocation.id">
						<div class="card-body p-0">
							<div class="accordion" :id="'accordionItemDeadlines' + registry.id">
								<div class="accordion-item">
									<div class="accordion-header" id="header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											:data-bs-target="'#collapseOne-' + registryLocation.id"
											aria-expanded="true"
											:aria-controls="'#collapseOne-' + registryLocation.id"
											v-on:click="toggleAccordion"
											style="flex-wrap: wrap;">
											<div>
												{{ registry.name }}
											</div>
											&nbsp;
											<div class="text-muted">
												{{ registryLocation.address }},
												{{ registryLocation.city }}
												<span v-if="registryLocation.province_code">
													({{ registryLocation.province_code }})
												</span>
											</div>
											&nbsp;
											<div class="bg-info item-deadlines-count-container" style="border: 1px solid RGBA(108,117,125,var(--bs-bg-opacity,1))">
												{{ fireExtinguisherCount(registryLocation) }}
											</div>
											<div class="bg-white item-deadlines-count-container" style="color: RGBA(108,117,125,var(--bs-bg-opacity,1)); border: 1px solid RGBA(108,117,125,var(--bs-bg-opacity,1));">
												CS: {{ controlloSemestraleCount(registryLocation)}}
											</div>
											<div class="bg-warning item-deadlines-count-container" style="border: 1px solid RGBA(108,117,125,var(--bs-bg-opacity,1))">
												RC: {{ RitiroCollaudoCount(registryLocation)}}
											</div>
											<div class="bg-primary item-deadlines-count-container" style="border: 1px solid RGBA(108,117,125,var(--bs-bg-opacity,1))">
												RR: {{ RitiroRevisioneCount(registryLocation)}}
											</div>
											<div class="bg-danger item-deadlines-count-container" style="border: 1px solid RGBA(108,117,125,var(--bs-bg-opacity,1))">
												FV: {{ fineVitaCount(registryLocation)}}
											</div>
										</button>
									</div>
									<div :id="'collapseOne-' + registryLocation.id" class="accordion-collapse collapse" aria-labelledby="header" :data-bs-parent="'#accordionItemDeadlines' + registry.id">
										<div class="accordion-body p-0 py-1">
											<div class="table-responsive">
												<table class="table table-bordered table-vmiddle mb-0">
													<tbody>
														<template v-for="item in registryLocation.items">
															<template v-for="itemDeadline in item.item_deadlines">
																<tr :key="itemDeadline.id" :class="getItemDeadlineColor(itemDeadline)">
																	<td class="text-center text-nowrap py-1 px-1" width="1%">
																		<button type="button" class="btn btn-secondary" @click="recordMaintenanceOnItemDeadline(registry, registryLocation, item, itemDeadline)" v-if="itemDeadline.completed_at === null && !hasMaintenanceForDeadline(itemDeadline) && canRecordMaintenanceForItem(item)">
																			Registra intevento
																		</button>
																		<button type="button" class="btn btn-warning" disabled v-else-if="hasMaintenanceToBeSyncedForDeadline(itemDeadline)">
																			Da sincronizzare
																		</button>
																		<button type="button" class="btn btn-success" disabled v-else-if="hasSyncedMaintenanceForDeadline(itemDeadline)">
																			Sincronizzato
																		</button>
																	</td>
																	<td class="text-nowrap">
																		<span v-if="item.object_type">
																			{{ item.object_type.name }}
																		</span>
																		<strong>{{ item.registration_number }}</strong>
																	</td>
																	<td class="text-center text-nowrap" width="1%">
																		{{ item.object_features.map(feature => feature.name).join(", ") }}
																	</td>
																	<td class="text-center text-nowrap" width="1%">
																		{{ item.fire_class }}
																	</td>
																	<td class="text-center text-nowrap" width="1%">
																		{{ item.date }}
																	</td>
																	<td class="text-right text-nowrap" width="1%">
																		{{ item.quantity }} Kg
																	</td>
																	<td class="text-center text-nowrap" width="1%">
																		<span v-if="itemDeadline.deadline_rule && itemDeadline.deadline_rule.deadline_type">
																			{{ itemDeadline.deadline_rule.deadline_type.name }}
																		</span>
																	</td>
																	<td class="text-nowrap" width="1%">
																		{{ item.location }}
																	</td>
																	<td class="text-center text-nowrap" width="1%">
																		{{ itemDeadline.date.split("-").reverse().join("/") }}
																	</td>
																	<td class="text-center text-nowrap" width="1%">
																		<b style="font-size: 10px;">ULTIMA REVISIONE</b>
																		<br>
																		{{ item.latest_revision ? item.latest_revision.split("-").reverse().join("/") : (item.last_revision ? item.last_revision.split("-").reverse().join("/") : "--") }}
																	</td>
																	<td class="text-center text-nowrap" width="1%">
																		<b style="font-size: 10px;">ULTIMO COLLAUDO</b>
																		<br>
																		{{ item.latest_testing ? item.latest_testing.split("-").reverse().join("/") : (item.last_testing ? item.last_testing.split("-").reverse().join("/") : "--") }}
																	</td>
																	<td class="text-center text-nowrap" width="1%">
																		<b style="font-size: 10px;">ULTIMO CONTROLLO</b>
																		<br>
																		{{ item.latest_control ? item.latest_control.split("-").reverse().join("/") : "--" }}
																	</td>
																</tr>
															</template>
														</template>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import localforage from "localforage"
import AnnotationsRepository from "@/repositories/annotations-repository"
import DeadlinesByRegistryRepository from "@/repositories/deadlines-by-registry-repository"
import MaintenanceEnablementOperationsRepository from "@/repositories/maintenance-enablement-operations-repository"
import MaintenancesRepository from "@/repositories/maintenances-repository"
import AnnotationMarkAsCompletedOperationsRepository from "@/repositories/annotation-mark-as-completed-operations-repository"
import {
	currentDate, currentDatetime, formatDate,
} from '@/utils/datetime-utils'
import jsQR from "jsqr"

let annotationsRepository = new AnnotationsRepository()
let deadlinesByRegistryRepository = new DeadlinesByRegistryRepository()
let maintenanceEnablementOperationsRepository = new MaintenanceEnablementOperationsRepository()
let maintenancesRepository = new MaintenancesRepository()
let annotationMarkAsCompletedOperationsRepository = new AnnotationMarkAsCompletedOperationsRepository()

// document.getElementById('collapseOne-' + registryLocation.id).classList.add("show");

export default {
	name: "ItemDeadlinesIndexView",
	data () {
		return {
			loading: false,
			user: null,
			annotations: [],
			deadlinesByRegistry: [],
			maintenanceEnablementOperations: [],
			maintenances: [],
			selectedDate: currentDate(),

			showQrScanner: false,
			qrVideo: null,
			qrCanvasElement: null,
			qrCanvas: null,
			scannedCode: "",
		}
	},
	computed: {
		deadlinesByRegistryStorageKey () {
			return "DEADLINES_BY_REGISTRY__" + this.selectedDate
		},
		deadlinesForScannedCode () {
			let itemsFilterCallback = item => item.uuid == this.scannedCode
			let registryLocationsFilterCallback = registryLocation => registryLocation.items.find(itemsFilterCallback)
			let registriesFilterCallback = registry => registry.registry_locations.find(registryLocationsFilterCallback)

			let deadlinesCopy = JSON.parse(JSON.stringify((this.deadlinesByRegistry)))

			let filteredDeadlinesByRegistry = deadlinesCopy.filter(registriesFilterCallback)

			filteredDeadlinesByRegistry = filteredDeadlinesByRegistry.map(registry => {
				registry.registry_locations = registry.registry_locations.filter(registryLocationsFilterCallback)
				registry.registry_locations = registry.registry_locations.map(registryLocation => {
					registryLocation.items = registryLocation.items.filter(itemsFilterCallback)
					return registryLocation
				})

				return registry
			})

			return filteredDeadlinesByRegistry
		},
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			annotationsRepository.getAllAssigned().then(annotations => this.annotations = annotations),
			maintenanceEnablementOperationsRepository.getAll().then(maintenanceEnablementOperations => this.maintenanceEnablementOperations = maintenanceEnablementOperations),
			maintenancesRepository.getAll().then(maintenances => this.maintenances = maintenances),
			deadlinesByRegistryRepository.getByDate(this.selectedDate).then(deadlines => this.deadlinesByRegistry = deadlines),
		]).finally(() => this.loading = false)
	},
	methods: {
		formatDate: formatDate,
		updateDeadlinesByRegistry () {
			this.loading = true

			deadlinesByRegistryRepository.getByDate(this.selectedDate)
				.then(deadlines => this.deadlinesByRegistry = deadlines)
				.finally(() => this.loading = false)
		},
		recordMaintenanceOnItemDeadline (registry, registryLocation, item, itemDeadline) {
			this.loading = true

			itemDeadline.completed_at  = currentDatetime()
			itemDeadline.maintainer_id = this.user.id

			maintenancesRepository.create(registry, registryLocation, item, itemDeadline)
				.then(maintenances => this.maintenances = maintenances)
				.finally(() => this.loading = false)
		},
		maintenanceForDeadline (itemDeadline) {
			return this.maintenances.find(m => m.itemDeadline.id == itemDeadline.id)
		},
		maintenanceToBeSyncedForDeadline (itemDeadline) {
			return this.maintenances.find(m => m.itemDeadline.id == itemDeadline.id && m.synced_at == null)
		},
		syncedMaintenanceForDeadline (itemDeadline) {
			return this.maintenances.find(m => m.itemDeadline.id == itemDeadline.id && m.synced_at != null)
		},
		hasMaintenanceForDeadline (itemDeadline) {
			return this.maintenanceForDeadline(itemDeadline) !== undefined
		},
		hasMaintenanceToBeSyncedForDeadline (itemDeadline) {
			return this.maintenanceToBeSyncedForDeadline(itemDeadline) !== undefined
		},
		hasSyncedMaintenanceForDeadline (itemDeadline) {
			return this.syncedMaintenanceForDeadline(itemDeadline) !== undefined
		},
		canRecordMaintenanceForItem (item) {
			return this.maintenanceEnablementOperations.find(m => m.uuid == item.uuid && m.date == currentDate()) !== undefined
		},
		startQrScanner () {
			this.scannedCode = ""

			this.showQrScanner = true

			this.qrVideo = document.createElement("video")
			this.qrCanvasElement = document.getElementById("qr-scanner-canvas")
			this.qrCanvas = this.qrCanvasElement.getContext("2d")

			navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(stream => {
				this.qrVideo.srcObject = stream
				this.qrVideo.setAttribute("playsinline", true) // Required to tell iOS safari we don't want fullscreen
				this.qrVideo.play()
				requestAnimationFrame(this.qrScannerCanvasCallback)
			})
		},
		stopQrScanner () {
			if (this.qrVideo && this.qrVideo.srcObject) {
				this.qrVideo.srcObject.getTracks().forEach(track => track.stop())
				this.qrVideo.srcObject = null
			}

			this.showQrScanner = false
		},
		drawLineOnQrScannerCanvas (begin, end, color) {
			this.qrCanvas.beginPath();
			this.qrCanvas.moveTo(begin.x, begin.y);
			this.qrCanvas.lineTo(end.x, end.y);
			this.qrCanvas.lineWidth = 4;
			this.qrCanvas.strokeStyle = color;
			this.qrCanvas.stroke();
		},
		qrScannerCanvasCallback () {
			if (this.qrVideo && this.qrVideo.readyState == this.qrVideo.HAVE_ENOUGH_DATA) {
				this.qrCanvasElement.height = this.qrVideo.videoHeight;
				this.qrCanvasElement.width = this.qrVideo.videoWidth;
				this.qrCanvas.drawImage(this.qrVideo, 0, 0, this.qrCanvasElement.width, this.qrCanvasElement.height);

				let imageData = this.qrCanvas.getImageData(0, 0, this.qrCanvasElement.width, this.qrCanvasElement.height)
				let scannedCode = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })

				if (scannedCode) {
					this.drawLineOnQrScannerCanvas(scannedCode.location.topLeftCorner, scannedCode.location.topRightCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.topRightCorner, scannedCode.location.bottomRightCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.bottomRightCorner, scannedCode.location.bottomLeftCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.bottomLeftCorner, scannedCode.location.topLeftCorner, "#FF3B58");

					this.stopQrScanner()

					let uuid = scannedCode.data

					this.scannedCode = uuid

					if (this.maintenanceEnablementOperations.find(m => m.uuid == uuid && m.date == currentDate()) === undefined) {
						maintenanceEnablementOperationsRepository.create({ uuid: uuid, date: currentDate() })
							.then(maintenanceEnablementOperations => this.maintenanceEnablementOperations = maintenanceEnablementOperations)
					}

				}
			}

			requestAnimationFrame(this.qrScannerCanvasCallback)
		},
		fireExtinguisherCount(registryLocation) {
			return registryLocation.items.filter(item => item.object_type?.name == "Estintore").length;
		},
		controlloSemestraleCount(registryLocation) {
			let counter = 0;

			for (let i = 0; i < registryLocation.items.length; i++) {
				let item = registryLocation.items[i]
				for (let j = 0; j < item.item_deadlines.length; j++) {
					let itemDeadline = item.item_deadlines[j];
					if (itemDeadline.deadline_rule.name.indexOf("Controllo semestrale") != -1) {
						counter++;
					}
				}
			}

			return counter;
		},
		fineVitaCount(registryLocation) {
			let counter = 0;

			for (let i = 0; i < registryLocation.items.length; i++) {
				let item = registryLocation.items[i]
				for (let j = 0; j < item.item_deadlines.length; j++) {
					let itemDeadline = item.item_deadlines[j];
					if (itemDeadline.deadline_rule.name.indexOf("Fine vita") != -1) {
						counter++;
					}
				}
			}

			return counter;
		},
		RitiroCollaudoCount(registryLocation) {
			let counter = 0;

			for (let i = 0; i < registryLocation.items.length; i++) {
				let item = registryLocation.items[i]
				for (let j = 0; j < item.item_deadlines.length; j++) {
					let itemDeadline = item.item_deadlines[j];
					if (itemDeadline.deadline_rule.name.indexOf("Ritiro collaudo") != -1 || itemDeadline.deadline_rule.name.indexOf("Riconsegna collaudo") != -1) {
						counter++;
					}
				}
			}

			return counter;
		},
		RitiroRevisioneCount(registryLocation) {
			let counter = 0;

			for (let i = 0; i < registryLocation.items.length; i++) {
				let item = registryLocation.items[i]
				for (let j = 0; j < item.item_deadlines.length; j++) {
					let itemDeadline = item.item_deadlines[j];
					if (itemDeadline.deadline_rule.name.indexOf("Ritiro revisione") != -1 || itemDeadline.deadline_rule.name.indexOf("Riconsegna revisione") != -1) {
						counter++;
					}
				}
			}

			return counter;
		},
		toggleAccordion(event) {
			let button = event.currentTarget;
			button.classList.toggle("collapsed");
			let accordion = button.closest(".accordion");
			let collapsibleElement = accordion.querySelector(".accordion-collapse");

			collapsibleElement.classList.toggle("show");
		},
		markAsCompleted(annotation) {
			this.loading = true

			annotationMarkAsCompletedOperationsRepository.create({annotation_id: annotation.id, completed_by: this.user.id})
				.then(annotations => this.annotations = annotations)
				.finally(() => { this.loading = false })
		},
		getItemDeadlineColor(itemDeadline) {
			if (itemDeadline.deadline_rule.name.indexOf('Fine vita') != -1) {
				return "table-danger";
			}
			if (itemDeadline.deadline_rule.name.indexOf('Ritiro collaudo') != -1 || itemDeadline.deadline_rule.name.indexOf('Riconsegna collaudo') != -1) {
				return "table-warning";
			}
			if (itemDeadline.deadline_rule.name.indexOf('Ritiro revisione') != -1 || itemDeadline.deadline_rule.name.indexOf('Riconsegna revisione') != -1) {
				return "table-primary";
			}
			return "";
		}
	}
}
</script>

<style scoped>
#selected-date-input {
	font-size: calc(1.3rem + .6vw);
	border: none;
}

.item-deadlines-count-container {
	border-radius: 10px;
	width: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 5px;
	padding: 2px 4px;
	color: white;
	white-space: nowrap;
}
</style>