import localForage from 'localforage'

export default async function (to, from, next) {
	let user = await localForage.getItem("USER")

	if (user) {
		return next()
	}

	return next({ name: 'auth.login' })
}