import axios from 'axios'
import localforage from "localforage";

export default class ObjectFeaturesRepository {

	constructor () {
		this.repositoryNamespace = "OBJECT_FEATURES"
	}

	// --------------------------------------------------

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(objectFeatures => objectFeatures ?? [])
	}

	async replaceAll (objectFeatures) {
		return localforage.setItem(this.repositoryNamespace, objectFeatures)
	}

	async replaceAllFromRemote () {
		try {
			let objectFeatures = await axios.get("/object-features").then(response => response.data)
			return this.replaceAll(objectFeatures)
		} catch (error) {
			// Some errors are handled with an interceptor
		}

		return this.getAll()
	}

	// --------------------------------------------------

	async deleteAll () {
		let keys = await localforage.keysStartingWith(this.repositoryNamespace)

		return Promise.all(keys.map(key => localforage.setItem(key, [])))
	}
}