import axios from "axios";
import localforage from "localforage";
import { currentDatetime } from "@/utils/datetime-utils";

export default class ItemUpdateOperationsRepository {

	constructor () {
		this.repositoryNamespace = "ITEM_UPDATE_OPERATIONS"
	}

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(itemUpdateOperations => itemUpdateOperations ?? [])
	}

	async create (itemUpdateOperationData) {
		let itemUpdateOperation = {
			uuid: itemUpdateOperationData.uuid,
			registration_number: itemUpdateOperationData.registration_number,
			location: itemUpdateOperationData.location,
			object_features_ids: itemUpdateOperationData.object_features_ids,
			fire_class_id: itemUpdateOperationData.fire_class_id,
			date: itemUpdateOperationData.date,
			last_revision: itemUpdateOperationData.last_revision,
			last_testing: itemUpdateOperationData.last_testing,
			last_control: itemUpdateOperationData.last_control,
			notes: itemUpdateOperationData.notes,
			synced_at: null,
			created_at: currentDatetime(),
		}

		if (navigator.onLine) {
			let requestParams = {
				uuid: itemUpdateOperationData.uuid,
				registration_number: itemUpdateOperationData.registration_number,
				location: itemUpdateOperationData.location,
				object_features_ids: itemUpdateOperationData.object_features_ids,
				fire_class_id: itemUpdateOperationData.fire_class_id,
				date: itemUpdateOperationData.date,
				last_revision: itemUpdateOperationData.last_revision,
				last_testing: itemUpdateOperationData.last_testing,
				last_control: itemUpdateOperationData.last_control,
				notes: itemUpdateOperationData.notes,
			}

			try {
				await axios.post("/item-update-operations/create", requestParams)
				itemUpdateOperation.synced_at = currentDatetime()
			} catch (error) {
				// Some errors are handled with an interceptor
			}
		}

		let existingItemUpdateOperations = await localforage.getItem(this.repositoryNamespace) ?? []
		existingItemUpdateOperations.push(itemUpdateOperation)

		return localforage.setItem(this.repositoryNamespace, existingItemUpdateOperations)
	}

	async syncAll () {
		let existingItemUpdateOperations = await localforage.getItem(this.repositoryNamespace) ?? []

		for (let i = 0; i < existingItemUpdateOperations.length; i++) {
			let itemUpdateOperation = existingItemUpdateOperations[i]

			if (navigator.onLine && itemUpdateOperation.synced_at == null) {
				let requestParams = {
					uuid: itemUpdateOperation.uuid,
					registration_number: itemUpdateOperation.registration_number,
					location: itemUpdateOperation.location,
					object_features_ids: itemUpdateOperation.object_features_ids,
					fire_class_id: itemUpdateOperation.fire_class_id,
					date: itemUpdateOperation.date,
					last_revision: itemUpdateOperation.last_revision,
					last_testing: itemUpdateOperation.last_testing,
					last_control: itemUpdateOperation.last_control,
					notes: itemUpdateOperation.notes,
				}

				try {
					await axios.post("/item-update-operations/create", requestParams)
					existingItemUpdateOperations[i].synced_at = currentDatetime()
				} catch (error) {
					// Some errors are handled with an interceptor
				}
			}
		}

		return localforage.setItem(this.repositoryNamespace, existingItemUpdateOperations)
	}

	// --------------------------------------------------

	async deleteAll () {
		return localforage.setItem(this.repositoryNamespace, [])
	}
}