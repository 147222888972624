<template>
	<nav class="navbar navbar-expand-lg navbar-dark mb-3" :class="{
		'bg-warning': isDevelopmentEnvironment,
		'bg-primary': isProductionEnvironment,
	}">
		<div class="container">
			<router-link to="/" class="navbar-brand">
				Linearossa
			</router-link>
			<div class="navbar-nav">
				<div class="nav-item" v-if="isDevelopmentEnvironment">
					<a href="#" class="nav-link text-white">
						DEVELOPMENT
					</a>
				</div>
				<div class="nav-item" v-if="isOffline">
					<a href="#" class="nav-link text-yellow">
						&#9888;
						Sei offline
					</a>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name: "AppHeader",
	data () {
		return {
			isOnline: navigator.onLine,
		}
	},
	mounted () {
		window.addEventListener("online",  () => this.isOnline = true)
		window.addEventListener("offline", () => this.isOnline = false)
	},
	computed: {
		isOffline () {
			return !this.isOnline
		},
		isProductionEnvironment () {
			return document.location.hostname == "app.linearossa.servonet.it"
		},
		isDevelopmentEnvironment () {
			return !this.isProductionEnvironment
		},
	}
}
</script>