import localforage from "localforage";

export default class MaintenanceEnablementOperationsRepository {

	constructor () {
		this.repositoryNamespace = "MAINTENANCE_ENABLEMENT_OPERATIONS"
	}

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(maintenanceEnablementOperations => maintenanceEnablementOperations ?? [])
	}

	async create (maintenanceEnablementOperationData) {
		let maintenanceEnablementOperation = {
			uuid: maintenanceEnablementOperationData.uuid,
			date: maintenanceEnablementOperationData.date
		}

		let existingMaintenanceEnablementOperations = await localforage.getItem(this.repositoryNamespace) ?? []
		existingMaintenanceEnablementOperations.push(maintenanceEnablementOperation)

		return localforage.setItem(this.repositoryNamespace, existingMaintenanceEnablementOperations)
	}

	// --------------------------------------------------

	async deleteAll () {
		return localforage.setItem(this.repositoryNamespace, [])
	}
}