<template>
	<div id="label-replacement-without-qr-code-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Sostituzione etichetta
			</h1>
		</div>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="card mb-3">
				<div class="card-body">
					<p class="card-text">
						Codice vecchio: {{ oldScannedCode }}
					</p>
					<p class="card-text">
						Codice nuovo: {{ newScannedCode }}
					</p>
				</div>
			</div>

			<button class="btn w-100 mb-3" :class="oldScannedCode ? 'btn-success' : 'btn-primary'" type="button" @click="showOldItemSearchForm = true" v-show="!showOldItemSearchForm">
				Cerca codice vecchio
			</button>

			<div class="card mb-3" v-show="showOldItemSearchForm">
				<div class="card-body">
					<h4 class="card-title">
						Ricerca codice vecchio
					</h4>

					<div class="form-group mb-3">
						<label class="form-label">Matricola</label>
						<input type="text" class="form-control" v-model="oldItemFilters.registrationNumber">
					</div>

					<div class="form-group mb-3">
						<label class="form-label">Ricerca anagrafica</label>
						<input type="text" class="form-control" v-model="oldItemFilters.registryName">
					</div>

					<div class="form-group mb-3">
						<label class="form-label">Seleziona anagrafica</label>
						<select class="form-control" v-model="oldItemFilters.registryId" :disabled="availableRegistries.length == 0">
							<option v-for="registry in availableRegistries" :key="registry.id" :value="registry.id">{{ registry.name }}</option>
						</select>
					</div>

					<div class="form-group mb-3">
						<label class="form-label">Seleziona sede</label>
						<select class="form-control" v-model="oldItemFilters.registryLocationId" :disabled="availableRegistryLocations.length == 0">
							<option v-for="registryLocation in availableRegistryLocations" :key="registryLocation.id" :value="registryLocation.id">
								{{ registryLocation.address }}
								-
								{{ registryLocation.zip }}
								{{ registryLocation.city }}
								({{ registryLocation.province_code }})
							</option>
						</select>
					</div>

					<!-- <button class="btn btn-primary w-100" type="button" @click="findOldItem()" :disabled="oldItemFilters.registrationNumber.length == 0">
						Cerca
					</button> -->
					<button class="btn btn-primary w-100" type="button" @click="findOldItems()" :disabled="oldItemFilters.registrationNumber.length == 0 && oldItemFilters.registryName.length == 0">
						Cerca
					</button>
				</div>
			</div>

			<div class="card mb-3" v-if="oldItems.length > 0">
				<div class="table-responsive">
					<table class="table table-bordered table-vmiddle mb-0">
						<thead>
							<tr>
								<th class="text-nowrap">Oggetto</th>
								<th class="text-nowrap">Cliente</th>
								<th class="text-nowrap">Caratteristiche</th>
								<th class="text-nowrap">Quantità</th>
								<th class="text-nowrap">Ubicazione</th>
								<th class="text-nowrap"></th>
							</tr>
						</thead>
						<tbody>
							<template v-for="item in oldItems">
								<tr :key="item.id">
									<td class="text-nowrap">
										<span v-if="item.object_type">
											{{ item.object_type.name }}
										</span>
										<strong>{{ item.registration_number }}</strong>
									</td>
									<td class="text-center text-nowrap" width="1%">
										{{ item.registry.name }}
									</td>
									<td class="text-center text-nowrap" width="1%">
										{{ item.object_features.map(feature => feature.name ).join(", ") }}
									</td>
									<td class="text-right text-nowrap" width="1%">
										{{ item.quantity }} Kg
									</td>
									<td class="text-nowrap" width="1%">
										{{ item.location }}
									</td>
									<td class="text-center text-nowrap py-1 px-1" width="1%" @click="selectOldItem(item)">
										<button type="button" class="btn btn-secondary">
											Seleziona
										</button>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>

			<button class="btn w-100 mb-3" :class="newScannedCode ? 'btn-success' : 'btn-primary'" type="button" @click="startNewQrScanner()" v-show="!showNewQrScanner">
				Scansiona codice nuovo
			</button>

			<div class="card mb-3" v-show="showNewQrScanner">
				<div class="card-body">
					<h4 class="card-title">
						Scansiona codice nuovo
					</h4>
					<canvas id="new-qr-scanner-canvas" style="border: solid 1px black; width: 100%;"></canvas>
				</div>
			</div>

			<button class="btn btn-primary w-100 mb-3" type="button" @click="confirmLabelReplacement()" :disabled="!(oldScannedCode && newScannedCode)">
				Conferma
			</button>
		</div>
	</div>
</template>

<script>
import localforage from "localforage"
import jsQR from "jsqr"

import ItemsRepository from '@/repositories/items-repository'
import LabelReplacementOperationsRepository from '@/repositories/label-replacement-operations-repository'
import RegistriesRepository from '@/repositories/registries-repository'

let itemsRepository = new ItemsRepository()
let labelReplacementOperationsRepository = new LabelReplacementOperationsRepository()
let registriesRepository = new RegistriesRepository()

let itemsList = [] // Initialized here since we do not need it to be reactive

export default {
	name: "LabelReplacementWithoutQrCodeView",
	data () {
		return {
			loading: false,
			registries: [],
			oldItems: [],

			oldItemFilters: {
				registrationNumber: "",
				registryName: "",
				registryId: null,
				registryLocationId: null,
			},

			showOldItemSearchForm: true,
			oldScannedCode: "",
			oldSelectedItem: null,

			showNewQrScanner: false,
			newScannedCode: "",
			newSelectedItem: null,
			newQrVideo: null,
			newQrCanvasElement: null,
			newQrCanvas: null,
		}
	},
	computed: {
		availableRegistries () {
			if (this.oldItemFilters.registryName.length < 3) {
				return []
			}

			return this.registries.filter(r => r.name.toLowerCase().indexOf(this.oldItemFilters.registryName.toLowerCase()) > -1)
		},
		selectedRegistry () {
			if (!this.oldItemFilters.registryId) {
				return null
			}

			return this.registries.find(r => r.id == this.oldItemFilters.registryId)
		},
		availableRegistryLocations () {
			if (this.selectedRegistry == null) {
				return []
			}

			return this.selectedRegistry.registry_locations
		},
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			itemsRepository.getAll().then(items => itemsList = items),
			registriesRepository.getAll().then(registries => this.registries = registries),
		]).finally(() => this.loading = false)
	},
	beforeDestroy () {
		this.stopNewQrScanner()
	},
	methods: {
		findItemByUuid (uuid) {
			return itemsList.find(item => item.uuid == uuid)
		},
		findOldItem () {
			let filters = this.oldItemFilters

			let item = itemsList.find(i => {
				let found1 = filters.registrationNumber ? i.registration_number  == filters.registrationNumber : true
				let found2 = filters.registryId         ? i.registry_id          == filters.registryId         : true
				let found3 = filters.registryLocationId ? i.registry_location_id == filters.registryLocationId : true

				return found1 && found2 && found3
			})

			if (item) {
				this.selectOldItem(item)
			} else {
				this.oldSelectedItem = null
				this.oldScannedCode  = ""
			}
		},
		findOldItems () {
			let filters = this.oldItemFilters

			this.oldItems = itemsList.filter(i => {
				let found1 = filters.registrationNumber ? i.registration_number  == filters.registrationNumber : true
				let found2 = filters.registryId         ? i.registry_id          == filters.registryId         : true
				let found3 = filters.registryLocationId ? i.registry_location_id == filters.registryLocationId : true

				return found1 && found2 && found3
			})
		},
		selectOldItem (item) {
			this.oldSelectedItem = item
			this.oldScannedCode  = item.uuid

			this.showOldItemSearchForm = false

			this.oldItems = []
		},
		startNewQrScanner () {
			this.showNewQrScanner = true

			this.newVideo = document.createElement("video")
			this.newCanvasElement = document.getElementById("new-qr-scanner-canvas")
			this.newCanvas = this.newCanvasElement.getContext("2d")

			navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(stream => {
				this.newVideo.srcObject = stream
				this.newVideo.setAttribute("playsinline", true) // Required to tell iOS safari we don't want fullscreen
				this.newVideo.play()
				requestAnimationFrame(this.newQrScannerCanvasCallback)
			})
		},
		stopNewQrScanner () {
			if (this.newVideo && this.newVideo.srcObject) {
				this.newVideo.srcObject.getTracks().forEach(track => track.stop())
				this.newVideo.srcObject = null
			}

			this.showNewQrScanner = false
		},
		drawLineOnNewQrScannerCanvas (begin, end, color) {
			this.newCanvas.beginPath();
			this.newCanvas.moveTo(begin.x, begin.y);
			this.newCanvas.lineTo(end.x, end.y);
			this.newCanvas.lineWidth = 4;
			this.newCanvas.strokeStyle = color;
			this.newCanvas.stroke();
		},
		newQrScannerCanvasCallback () {
			if (this.newVideo && this.newVideo.readyState == this.newVideo.HAVE_ENOUGH_DATA) {
				this.newCanvasElement.height = this.newVideo.videoHeight;
				this.newCanvasElement.width = this.newVideo.videoWidth;
				this.newCanvas.drawImage(this.newVideo, 0, 0, this.newCanvasElement.width, this.newCanvasElement.height);

				let imageData = this.newCanvas.getImageData(0, 0, this.newCanvasElement.width, this.newCanvasElement.height)
				let scannedCode = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })

				if (scannedCode) {
					this.drawLineOnNewQrScannerCanvas(scannedCode.location.topLeftCorner, scannedCode.location.topRightCorner, "#FF3B58");
					this.drawLineOnNewQrScannerCanvas(scannedCode.location.topRightCorner, scannedCode.location.bottomRightCorner, "#FF3B58");
					this.drawLineOnNewQrScannerCanvas(scannedCode.location.bottomRightCorner, scannedCode.location.bottomLeftCorner, "#FF3B58");
					this.drawLineOnNewQrScannerCanvas(scannedCode.location.bottomLeftCorner, scannedCode.location.topLeftCorner, "#FF3B58");

					this.newScannedCode = scannedCode.data
					this.newSelectedItem = this.findItemByUuid(scannedCode.data)

					this.stopNewQrScanner()
				}
			}

			requestAnimationFrame(this.newQrScannerCanvasCallback)
		},
		confirmLabelReplacement () {
			this.loading = true

			labelReplacementOperationsRepository.create({old_uuid: this.oldScannedCode, new_uuid: this.newScannedCode})
				.finally(() => {
					this.loading = false
					this.$router.push({name: 'home'})
				})
		},
	},
	watch: {
		availableRegistries (newValue) {
			if (newValue.length == 0) {
				this.oldItemFilters.registryId = null
				this.oldItemFilters.registryLocationId = null
			}
		},
	}
}
</script>