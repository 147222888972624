import axios from "axios";
import localforage from "localforage";
import { currentDatetime } from "@/utils/datetime-utils";

export default class AnnotationsRepository {

	constructor () {
		this.repositoryNamespace = "ANNOTATIONS"
	}

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(annotations => annotations ?? [])
	}

	// --------------------------------------------------

	async getAllAssigned () {
		return localforage.getItem(this.repositoryNamespace + "__ASSIGNED").then(annotations => annotations ?? [])
	}

	async replaceAllAssigned (annotations) {
		return localforage.setItem(this.repositoryNamespace + "__ASSIGNED", annotations)
	}

	async replaceAllAssignedFromRemote () {
		try {
			let annotations = await axios.get("/annotations/assigned").then(response => response.data)
			return this.replaceAllAssigned(annotations)
		} catch (error) {
			// Some errors are handled with an interceptor
		}

		return this.getAllAssigned()
	}

	// --------------------------------------------------

	async create (annotationData) {
		let annotation = {
			registry_id:          annotationData.registry_id,
			registry_location_id: annotationData.registry_location_id,
			uuid:                 annotationData.uuid,
			title:                annotationData.title,
			description:          annotationData.description,
			priority:             annotationData.priority,
			date:                 annotationData.date,
			annotationDocuments:  annotationData.annotationDocuments,
			synced_at:            null,
			created_at:           currentDatetime(),
		}

		if (navigator.onLine) {
			let requestParams = new FormData();
			let { annotationDocuments, ...annotationParams } = annotation

			Object.keys(annotationParams).forEach(key => requestParams.append(key, annotation[key]))

			for (let i = 0; i < annotationDocuments.length; i++) {
				requestParams.append("annotation_documents[]", annotationDocuments[i], "IMG_" + ("0000" + (i + 1)).slice(-4) + ".png")
			}

			try {
				await axios.post("/annotations/create", requestParams)
				annotation.synced_at = currentDatetime()
			} catch (error) {
				// Some errors are handled with an interceptor
			}
		}

		let existingAnnotations = await localforage.getItem(this.repositoryNamespace) ?? []
		existingAnnotations.push(annotation)

		return localforage.setItem(this.repositoryNamespace, existingAnnotations)
	}

	async syncAll () {
		let existingAnnotations = await localforage.getItem(this.repositoryNamespace) ?? []

		for (let i = 0; i < existingAnnotations.length; i++) {
			let annotation = existingAnnotations[i]

			if (navigator.onLine && annotation.synced_at == null) {
				let requestParams = new FormData();
				let { annotationDocuments, ...annotationParams } = annotation

				Object.keys(annotationParams).forEach(key => requestParams.append(key, annotation[key]))

				for (let i = 0; i < annotationDocuments.length; i++) {
					requestParams.append("annotation_documents[]", annotationDocuments[i], "IMG_" + ("0000" + (i + 1)).slice(-4) + ".png")
				}

				try {
					await axios.post("/annotations/create", requestParams)
					existingAnnotations[i].synced_at = currentDatetime()
				} catch (error) {
					// Some errors are handled with an interceptor
				}
			}
		}

		return localforage.setItem(this.repositoryNamespace, existingAnnotations)
	}

	// --------------------------------------------------

	async deleteAll () {
		return localforage.setItem(this.repositoryNamespace, [])
	}

	async deleteAllAssigned () {
		return localforage.setItem(this.repositoryNamespace + "__ASSIGNED", [])
	}
}