<template>
	<div id="home-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Home
			</h1>
		</div>

		<router-link :to="{name: 'item-deadlines.index'}" class="btn btn-primary mb-3 w-100">
			Lista interventi da fare
		</router-link>

		<router-link :to="{name: 'maintenances.index'}" class="btn btn-secondary mb-3 w-100">
			Lista interventi eseguiti
		</router-link>

		<router-link :to="{name: 'label-replacement.index'}" class="btn btn-secondary mb-3 w-100">
			Sostituzione etichetta
		</router-link>

		<router-link :to="{name: 'annotations.index'}" class="btn btn-secondary mb-3 w-100">
			Apertura segnalazione
		</router-link>

		<router-link :to="{name: 'items.create'}" class="btn btn-secondary mb-3 w-100">
			Inserimento oggetto
		</router-link>

		<router-link :to="{name: 'items.info-by-uuid'}" class="btn btn-secondary mb-3 w-100">
			Informazioni oggetto tramite QR
		</router-link>

		<hr class="mt-0">

		<router-link :to="{name: 'data-sync'}" class="btn btn-info mb-3 w-100">
			Sincronizzazione dati
		</router-link>

		<hr class="mt-0">

		<p class="text-center">
			Connesso come <span class="text-primary" v-if="user">{{ user.username }}</span>
		</p>

		<button class="btn btn-danger w-100 mb-3" type="button" @click="performLogout">
			Logout
		</button>
	</div>
</template>

<script>
import localforage from "localforage"

export default {
	name: "HomeView",
	data () {
		return {
			loading: false,
			user: null,
		}
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
		]).finally(() => this.loading = false)
	},
	methods: {
		performLogout () {
			localforage.removeItem("USER").then(() => this.$router.replace({ name: "auth.login" }))
		},
	}
}
</script>