import axios from 'axios'
import localforage from "localforage";

export default class RegistriesRepository {

	constructor () {
		this.repositoryNamespace = "REGISTRIES"
	}

	// --------------------------------------------------

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(registries => registries ?? [])
	}

	async replaceAll (registries) {
		return localforage.setItem(this.repositoryNamespace, registries)
	}

	async replaceAllFromRemote () {
		try {
			let registries = await axios.get("/registries").then(response => response.data)
			return this.replaceAll(registries)
		} catch (error) {
			// Some errors are handled with an interceptor
		}

		return this.getAll()
	}

	// --------------------------------------------------

	async getAllForAssignedItemDeadlines () {
		return localforage.getItem(this.repositoryNamespace + "__FOR_ASSIGNED_ITEM_DEADLINES").then(registries => registries ?? [])
	}

	async replaceAllForAssignedItemDeadlines (registries) {
		return localforage.setItem(this.repositoryNamespace + "__FOR_ASSIGNED_ITEM_DEADLINES", registries)
	}

	async replaceAllForAssignedItemDeadlinesFromRemote () {
		try {
			let registries = await axios.get("/registries/for-assigned-item-deadlines").then(response => response.data)
			return this.replaceAllForAssignedItemDeadlines(registries)
		} catch (error) {
			// Some errors are handled with an interceptor
		}

		return this.getAllForAssignedItemDeadlines()
	}

	// --------------------------------------------------

	async deleteAll () {
		let keys = await localforage.keysStartingWith(this.repositoryNamespace)

		return Promise.all(keys.map(key => localforage.setItem(key, [])))
	}
}