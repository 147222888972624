<template>
	<div id="annotations-create-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Inserisci segnalazione
			</h1>
		</div>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<router-link :to="{name: 'annotations.index'}" class="btn btn-secondary mb-3 w-100">
				Indietro
			</router-link>

			<div class="row mb-3">
				<div class="col">
					<button class="btn w-100" :class="isRegistrySelectionVisible ? 'btn-primary' : 'btn-secondary'" @click="showRegistrySelection()">Seleziona anagrafica</button>
				</div>
				<div class="col">
					<button class="btn w-100" :class="isQrCodeScannerVisible ? 'btn-primary' : 'btn-secondary'" @click="showQrCodeScanner()">Scansiona codice QR</button>
				</div>
			</div>

			<div class="card mb-3" v-show="isRegistrySelectionVisible">
				<div class="card-body">
					<div class="form-group mb-3">
						<label for="registry_id" class="control-label">Anagrafica</label>
						<select name="registry_id" class="form-control" v-model="annotation.registry_id">
							<option v-for="registry in registries" :key="registry.id" :value="registry.id">{{ registry.name }}</option>
						</select>
					</div>
					<div class="form-group mb-0">
						<label for="registry_location_id" class="control-label">Sede</label>
						<select name="registry_location_id" class="form-control" v-model="annotation.registry_location_id">
							<option v-for="registryLocation in registryLocations" :key="registryLocation.id" :value="registryLocation.id">
								{{ registryLocation.address }}
								-
								{{ registryLocation.zip }}
								{{ registryLocation.city }}
								({{ registryLocation.province_code }})
							</option>
						</select>
					</div>
				</div>
			</div>

			<div class="card mb-3" v-show="isQrCodeScannerVisible">
				<div class="card-body">
					<h4 class="card-title">
						Scansiona codice
					</h4>
					<canvas id="qr-scanner-canvas" style="border: solid 1px black; width: 100%;"></canvas>
				</div>
			</div>

			<div class="card mb-3">
				<div class="card-body">
					<div class="form-group mb-3" v-if="annotation.uuid">
						<label for="date" class="control-label">Codice scansionato</label>
						<input type="text" class="form-control" v-model="annotation.uuid" disabled>
					</div>
					<div class="form-group mb-3">
						<label for="date" class="control-label">Data</label>
						<input type="date" class="form-control" name="date" v-model="annotation.date">
					</div>
					<div class="form-group mb-3">
						<label for="title" class="control-label">Titolo</label>
						<input type="text" class="form-control" name="title" v-model="annotation.title" maxlength="250">
					</div>
					<div class="form-group mb-3">
						<label for="description" class="control-label">Descrizione</label>
						<textarea name="description" rows="3" class="form-control" v-model="annotation.description" maxlength="500"></textarea>
					</div>
					<div class="form-group mb-3">
						<label for="priority" class="control-label">Priorità</label>
						<select name="priority" class="form-control" v-model="annotation.priority">
							<option value="Bassa">Bassa</option>
							<option value="Media">Media</option>
							<option value="Alta">Alta</option>
						</select>
					</div>
					<div class="form-group mb-3">
						<button class="btn btn-secondary w-100" type="button" @click="clickOnFileInput()">
							Scatta fotografia
						</button>

						<input type="file" id="annotation-documents-input" accept="image/*" @change="handleFileSelection()" style="display: none" multiple>

						<div v-if="annotation.annotationDocuments.length > 0" style="display: grid; grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); padding: 8px; gap: 8px; background-color: #ecf0f1; border: solid 1px #ced4da;" class="mt-3">
							<div class="annotation-document-preview-container" v-for="(annotationDocument, index) in annotation.annotationDocuments" :key="index">
								<div style="border: solid 1px #ced4da; background-color: darkgrey; display: grid; grid-template-rows: auto auto;">
									<div style="display: flex; justify-content: center; align-items: center;">
										<img :src="createObjectUrlForBlob(annotationDocument)" class="img-fluid" style="max-height: 100%">
									</div>
									<button class="btn btn-light w-100 text-red" @click="annotation.annotationDocuments.splice(index, 1)">
										Elimina
									</button>
								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-primary w-100" type="button" @click="createAnnotation()">
						Inserisci
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import localforage from "localforage"
import jsQR from "jsqr"
import { currentDate } from '@/utils/datetime-utils'

import AnnotationsRepository from "@/repositories/annotations-repository"
import RegistriesRepository from "@/repositories/registries-repository"

let annotationsRepository = new AnnotationsRepository()
let registriesRepository = new RegistriesRepository()

export default {
	name: "AnnotationsCreateView",
	data () {
		return {
			loading: false,
			user: null,
			registries: [],
			annotation: {
				registry_id: null,
				registry_location_id: null,
				uuid: "",
				title: "",
				description: "",
				priority: "Bassa",
				date: currentDate(),
				annotationDocuments: [],
			},
			isRegistrySelectionVisible: true,
			isQrCodeScannerVisible: false,
			qrVideo: null,
			qrCanvasElement: null,
			qrCanvas: null,
		}
	},
	computed: {
		selectedRegistry () {
			return this.registries.find(r => r.id == this.annotation.registry_id)
		},
		registryLocations () {
			return this.selectedRegistry ? this.selectedRegistry.registry_locations : []
		},
	},
	beforeDestroy () {
		this.stopQrScanner()
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			registriesRepository.getAllForAssignedItemDeadlines().then(registries => this.registries = registries)
		]).finally(() => this.loading = false)
	},
	methods: {
		showRegistrySelection () {
			this.annotation.registry_id = null
			this.annotation.registry_location_id = null
			this.annotation.uuid = ""
			this.isRegistrySelectionVisible = true
			this.isQrCodeScannerVisible = false

			this.stopQrScanner()
		},
		showQrCodeScanner () {
			this.annotation.registry_id = null
			this.annotation.registry_location_id = null
			this.annotation.uuid = ""
			this.isRegistrySelectionVisible = false
			this.isQrCodeScannerVisible = true

			this.startQrScanner()
		},
		startQrScanner () {
			this.isQrCodeScannerVisible = true

			this.qrVideo = document.createElement("video")
			this.qrCanvasElement = document.getElementById("qr-scanner-canvas")
			this.qrCanvas = this.qrCanvasElement.getContext("2d")

			navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(stream => {
				this.qrVideo.srcObject = stream
				this.qrVideo.setAttribute("playsinline", true) // Required to tell iOS safari we don't want fullscreen
				this.qrVideo.play()
				requestAnimationFrame(this.qrScannerCanvasCallback)
			})
		},
		stopQrScanner () {
			if (this.qrVideo && this.qrVideo.srcObject) {
				this.qrVideo.srcObject.getTracks().forEach(track => track.stop())
				this.qrVideo.srcObject = null
			}

			this.isQrCodeScannerVisible = false
		},
		drawLineOnQrScannerCanvas (begin, end, color) {
			this.qrCanvas.beginPath();
			this.qrCanvas.moveTo(begin.x, begin.y);
			this.qrCanvas.lineTo(end.x, end.y);
			this.qrCanvas.lineWidth = 4;
			this.qrCanvas.strokeStyle = color;
			this.qrCanvas.stroke();
		},
		qrScannerCanvasCallback () {
			if (this.qrVideo && this.qrVideo.readyState == this.qrVideo.HAVE_ENOUGH_DATA) {
				this.qrCanvasElement.height = this.qrVideo.videoHeight;
				this.qrCanvasElement.width = this.qrVideo.videoWidth;
				this.qrCanvas.drawImage(this.qrVideo, 0, 0, this.qrCanvasElement.width, this.qrCanvasElement.height);

				let imageData = this.qrCanvas.getImageData(0, 0, this.qrCanvasElement.width, this.qrCanvasElement.height)
				let scannedCode = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })

				if (scannedCode) {
					this.drawLineOnQrScannerCanvas(scannedCode.location.topLeftCorner, scannedCode.location.topRightCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.topRightCorner, scannedCode.location.bottomRightCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.bottomRightCorner, scannedCode.location.bottomLeftCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.bottomLeftCorner, scannedCode.location.topLeftCorner, "#FF3B58");

					this.annotation.uuid = scannedCode.data

					this.stopQrScanner()
				}
			}

			requestAnimationFrame(this.qrScannerCanvasCallback)
		},
		createAnnotation () {
			this.loading = true

			annotationsRepository.create(this.annotation)
				.finally(() => {
					this.loading = false
					this.$router.push({name: 'home'})
				})
		},
		createObjectUrlForBlob (blob) {
			return URL.createObjectURL(blob)
		},
		clickOnFileInput () {
			document.getElementById('annotation-documents-input').click()
		},
		handleFileSelection () {
			let fileInput = document.getElementById("annotation-documents-input")
			let fileList  = fileInput.files

			for (let i = 0; i < fileList.length; i++) {
				this.annotation.annotationDocuments.push(fileList[i])
			}

			fileInput.value = ""
		},
	},
}
</script>