<template>
	<div id="annotations-index-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Segnalazioni aperte
			</h1>
		</div>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<router-link :to="{name: 'annotations.create'}" class="btn btn-primary mb-3 w-100">
				Inserisci segnalazione
			</router-link>

			<div v-if="annotations.length == 0">
				<div class="card mb-3">
					<div class="card-body">
						Nessuna segnalazione registrata.
					</div>
				</div>
			</div>
			<div v-else>
				<button class="btn btn-info w-100 mb-3" type="button" @click="syncAnnotations()" :disabled="annotationsToBeSynced.length == 0">
					Sincronizza segnalazioni ({{ annotationsToBeSynced.length }})
				</button>
				<div class="card mb-3">
					<div class="table-responsive">
						<table class="table table-bordered table-vmiddle mb-0">
							<thead>
								<tr>
									<th class="text-nowrap" width="1%">Anagrafica</th>
									<th class="text-nowrap" width="1%">Titolo</th>
									<th class="text-nowrap" width="1%">Data</th>
									<th class="text-nowrap" width="1%">Priorità</th>
									<th class="text-nowrap" width="1%">Sincronizzata</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="annotation in annotations" :key="annotation.created_at" :class="{'sync-status--success': annotation.synced_at != null}">
									<td class="text-nowrap">{{ annotation.registry ? annotation.registry.name : "" }}</td>
									<td class="text-nowrap">{{ annotation.title }}</td>
									<td class="text-nowrap">{{ formatDate(annotation.date) }}</td>
									<td class="text-nowrap">{{ annotation.priority }}</td>
									<td class="text-nowrap text-center">
										<span v-if="annotation.synced_at">Sì</span>
										<span v-else>No</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import localforage from "localforage"
import {
	formatDate,
	formatDatetime,
} from '@/utils/datetime-utils'

import AnnotationsRepository from '@/repositories/annotations-repository'

let annotationsRepository = new AnnotationsRepository()

export default {
	name: "AnnotationsIndexView",
	data () {
		return {
			loading: false,
			user: null,
			annotations: [],
		}
	},
	computed: {
		annotationsToBeSynced () {
			return this.annotations.filter(a => a.synced_at == null)
		}
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			annotationsRepository.getAll().then(annotations => this.annotations = annotations.sort((a, b) => a.created_at < b.created_at ? 1 : -1)),
		]).finally(() => this.loading = false)
	},
	methods: {
		formatDate: formatDate,
		formatDatetime: formatDatetime,
		syncAnnotations () {
			this.loading = true

			annotationsRepository.syncAll()
				.then(annotations => this.annotations = annotations)
				.finally(() => this.loading = false)
		},
	}
}
</script>

<style scoped>
	tr.sync-status--success th,
	tr.sync-status--success td {
		background-color: lightgreen;
	}
</style>