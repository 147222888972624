<template>
	<div id="maintenances-index-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Interventi eseguiti
			</h1>
		</div>


		<input type="date"
			id="selected-date-input"
			class="form-control bg-secondary text-white text-center mb-3"
			v-model="selectedDate"
			required>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<button class="btn btn-info w-100 mb-3" type="button" @click="syncMaintenances()" :disabled="maintenancesToBeSynced.length == 0">
				Sincronizza interventi ({{ maintenancesToBeSynced.length }})
			</button>

			<div v-if="maintenancesByDate.length == 0">
				<div class="card mb-3">
					<div class="card-body">
						Nessun intervento registrato nella data selezionata.
					</div>
				</div>
			</div>

			<div v-if="maintenancesByDate.length > 0">
				<div v-for="registry in maintenancesByRegistry" :key="registry.id">
					<div class="card mb-3" v-for="registryLocation in registry.registry_locations" :key="registryLocation.id">
						<div class="card-body p-0">
							<div class="accordion" :id="'accordionMaintenancesDeadlines' + registry.id">
								<div class="accordion-header" id="header">
									<button
										class="accordion-button"
										type="button"
										data-bs-toggle="collapse"
										:data-bs-target="'#collapseOne-' + registryLocation.id"
										aria-expanded="true"
										:aria-controls="'#collapseOne-' + registryLocation.id"
										v-on:click="toggleAccordion">
										<div>
											{{ registry.name }}
										</div>
										&nbsp;
										<div class="text-muted">
											{{ registryLocation.address }},
											{{ registryLocation.city }}
											<span v-if="registryLocation.province_code">
												({{ registryLocation.province_code }})
											</span>
										</div>
										<div class="bg-info item-deadlines-count-container" style="border: 1px solid RGBA(108,117,125,var(--bs-bg-opacity,1))">
											{{ fireExtinguisherCount(registryLocation) }}
										</div>
										<div class="bg-white item-deadlines-count-container" style="color: RGBA(108,117,125,var(--bs-bg-opacity,1)); border: 1px solid RGBA(108,117,125,var(--bs-bg-opacity,1));">
											CS: {{ controlloSemestraleCount(registryLocation)}}
										</div>
										<div class="bg-warning item-deadlines-count-container" style="border: 1px solid RGBA(108,117,125,var(--bs-bg-opacity,1))">
											RC: {{ RitiroCollaudoCount(registryLocation)}}
										</div>
										<div class="bg-primary item-deadlines-count-container" style="border: 1px solid RGBA(108,117,125,var(--bs-bg-opacity,1))">
											RR: {{ RitiroRevisioneCount(registryLocation)}}
										</div>
										<div class="bg-danger item-deadlines-count-container" style="border: 1px solid RGBA(108,117,125,var(--bs-bg-opacity,1))">
											FV: {{ fineVitaCount(registryLocation)}}
										</div>
									</button>
								</div>
								<div :id="'collapseOne-' + registryLocation.id" class="accordion-collapse collapse" aria-labelledby="header" :data-bs-parent="'#accordionMaintenancesDeadlines' + registry.id">
									<div class="accordion-body p-0 py-1">
										<div class="table-responsive">
											<table class="table table-bordered table-vmiddle mb-0">
												<tbody>
													<template v-for="item in registryLocation.items">
														<template v-for="itemDeadline in item.item_deadlines">
															<tr :key="itemDeadline.id"
															:class="[{
																'sync-status--warning': hasMaintenanceToBeSyncedForDeadline(itemDeadline),
																// 'sync-status--success': hasSyncedMaintenanceForDeadline(itemDeadline),
															}, getItemDeadlineColor(itemDeadline)]">
																<td class="text-nowrap">
																	<span v-if="item.object_type">
																		{{ item.object_type.name }}
																	</span>
																	<strong>{{ item.registration_number }}</strong>
																</td>
																<td class="text-center text-nowrap" width="1%">
																	{{ item.object_features.map(feature => feature.name ).join(", ") }}
																</td>
																<td class="text-center text-nowrap" width="1%">
																	{{ item.fire_class }}
																</td>
																<td class="text-center text-nowrap" width="1%">
																	{{ item.date }}
																</td>
																<td class="text-right text-nowrap" width="1%">
																	{{ item.quantity }} Kg
																</td>
																<td class="text-center text-nowrap" width="1%">
																	<span v-if="itemDeadline.deadline_rule && itemDeadline.deadline_rule.deadline_type">
																		{{ itemDeadline.deadline_rule.deadline_type.name }}
																	</span>
																</td>
																<td class="text-nowrap" width="1%">
																	{{ item.location }}
																</td>
																<td class="text-center text-nowrap" width="1%">
																	{{ itemDeadline.date.split("-").reverse().join("/") }}
																</td>
																<td class="text-center text-nowrap py-1 px-1" width="1%">
																	<span v-if="itemDeadline.completed_at">
																		<small>
																			{{ formatDate(itemDeadline.completed_at) }}
																		</small>
																		<br>
																		<small>
																			{{ itemDeadline.completed_at.split(" ")[1] }}
																		</small>
																	</span>
																</td>
															</tr>
														</template>
													</template>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import localforage from "localforage"
import {
	currentDate,
	formatDate,
	formatDatetime,
} from '@/utils/datetime-utils'

import MaintenancesRepository from '@/repositories/maintenances-repository'

let maintenancesRepository = new MaintenancesRepository()

export default {
	name: "MaintenancesIndexView",
	data () {
		return {
			loading: false,
			user: null,
			maintenances: [],
			selectedDate: currentDate(),
		}
	},
	computed: {
		maintenancesToBeSynced () {
			return this.maintenances.filter(m => m.synced_at == null)
		},
		maintenancesByDate () {
			return this.maintenances.filter(m => m.itemDeadline.completed_at.split(" ")[0] == this.selectedDate)
		},
		maintenancesByRegistry () {
			let maintenancesByRegistry = []

			for (let i = 0; i < this.maintenancesByDate.length; i++) {
				let maintenance = this.maintenancesByDate[i]

				let existingRegistryIndex = maintenancesByRegistry.findIndex(r => r.id == maintenance.registry.id)

				if (existingRegistryIndex === -1) {
					maintenancesByRegistry.push({
						...maintenance.registry,
						registry_locations: [],
					})

					existingRegistryIndex = maintenancesByRegistry.findIndex(r => r.id == maintenance.registry.id)
				}

				// --------------------------------------------------

				let existingRegistryLocationIndex = maintenancesByRegistry[existingRegistryIndex].registry_locations.findIndex(rl => rl.id == maintenance.registryLocation.id)

				if (existingRegistryLocationIndex === -1) {
					maintenancesByRegistry[existingRegistryIndex].registry_locations.push({
						...maintenance.registryLocation,
						items: [],
					})

					existingRegistryLocationIndex = maintenancesByRegistry[existingRegistryIndex].registry_locations.findIndex(rl => rl.id == maintenance.registryLocation.id)
				}

				// --------------------------------------------------

				let existingItemIndex = maintenancesByRegistry[existingRegistryIndex].registry_locations[existingRegistryLocationIndex].items.findIndex(i => i.id == maintenance.item.id)

				if (existingItemIndex === -1) {
					maintenancesByRegistry[existingRegistryIndex].registry_locations[existingRegistryLocationIndex].items.push({
						...maintenance.item,
						item_deadlines: [],
					})

					existingItemIndex = maintenancesByRegistry[existingRegistryIndex].registry_locations[existingRegistryLocationIndex].items.findIndex(i => i.id == maintenance.item.id)
				}

				// --------------------------------------------------

				maintenancesByRegistry[existingRegistryIndex].registry_locations[existingRegistryLocationIndex].items[existingItemIndex].item_deadlines.push(maintenance.itemDeadline)
			}

			maintenancesByRegistry = maintenancesByRegistry.sort((a, b) => a.name > b.name ? 1 : -1)

			return maintenancesByRegistry
		},
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			maintenancesRepository.getAll().then(maintenances => this.maintenances = maintenances.reverse()),
		]).finally(() => this.loading = false)
	},
	methods: {
		currentDate: currentDate,
		formatDate: formatDate,
		formatDatetime: formatDatetime,
		syncMaintenances () {
			this.loading = true

			maintenancesRepository.syncAll()
				.then(maintenances => this.maintenances = maintenances)
				.finally(() => this.loading = false)
		},
		maintenanceToBeSyncedForDeadline (itemDeadline) {
			return this.maintenances.find(m => m.itemDeadline.id == itemDeadline.id && m.synced_at == null)
		},
		syncedMaintenanceForDeadline (itemDeadline) {
			return this.maintenances.find(m => m.itemDeadline.id == itemDeadline.id && m.synced_at != null)
		},
		hasMaintenanceToBeSyncedForDeadline (itemDeadline) {
			return this.maintenanceToBeSyncedForDeadline(itemDeadline) !== undefined
		},
		hasSyncedMaintenanceForDeadline (itemDeadline) {
			return this.syncedMaintenanceForDeadline(itemDeadline) !== undefined
		},
		fireExtinguisherCount(registryLocation) {
			return registryLocation.items.filter(item => item.object_type?.name == "Estintore").length;
		},
		controlloSemestraleCount(registryLocation) {
			let counter = 0;

			for (let i = 0; i < registryLocation.items.length; i++) {
				let item = registryLocation.items[i]
				for (let j = 0; j < item.item_deadlines.length; j++) {
					let itemDeadline = item.item_deadlines[j];
					if (itemDeadline.deadline_rule.name.indexOf("Controllo semestrale") != -1) {
						counter++;
					}
				}
			}

			return counter;
		},
		fineVitaCount(registryLocation) {
			let counter = 0;

			for (let i = 0; i < registryLocation.items.length; i++) {
				let item = registryLocation.items[i]
				for (let j = 0; j < item.item_deadlines.length; j++) {
					let itemDeadline = item.item_deadlines[j];
					if (itemDeadline.deadline_rule.name.indexOf("Fine vita") != -1) {
						counter++;
					}
				}
			}

			return counter;
		},
		RitiroCollaudoCount(registryLocation) {
			let counter = 0;

			for (let i = 0; i < registryLocation.items.length; i++) {
				let item = registryLocation.items[i]
				for (let j = 0; j < item.item_deadlines.length; j++) {
					let itemDeadline = item.item_deadlines[j];
					if (itemDeadline.deadline_rule.name.indexOf("Ritiro collaudo") != -1 || itemDeadline.deadline_rule.name.indexOf("Riconsegna collaudo") != -1) {
						counter++;
					}
				}
			}

			return counter;
		},
		RitiroRevisioneCount(registryLocation) {
			let counter = 0;

			for (let i = 0; i < registryLocation.items.length; i++) {
				let item = registryLocation.items[i]
				for (let j = 0; j < item.item_deadlines.length; j++) {
					let itemDeadline = item.item_deadlines[j];
					if (itemDeadline.deadline_rule.name.indexOf("Ritiro revisione") != -1 || itemDeadline.deadline_rule.name.indexOf("Riconsegna revisione") != -1) {
						counter++;
					}
				}
			}

			return counter;
		},
		toggleAccordion(event) {
			let header = event.currentTarget;
			let accordion = header.closest(".accordion");
			let collapsibleElement = accordion.querySelector(".accordion-collapse");

			collapsibleElement.classList.toggle("show");
		},
		getItemDeadlineColor(itemDeadline) {
			if (itemDeadline.deadline_rule.name.indexOf('Fine vita') != -1) {
				return "table-danger";
			}
			if (itemDeadline.deadline_rule.name.indexOf('Ritiro collaudo') != -1 || itemDeadline.deadline_rule.name.indexOf('Riconsegna collaudo') != -1) {
				return "table-warning";
			}
			if (itemDeadline.deadline_rule.name.indexOf('Ritiro revisione') != -1 || itemDeadline.deadline_rule.name.indexOf('Riconsegna revisione') != -1) {
				return "table-primary";
			}
			return "";
		}
	}
}

</script>

<style scoped>
	#selected-date-input {
		font-size: calc(1.3rem + .6vw);
		border: none;
	}
	/* tr.sync-status--success th,
	tr.sync-status--success td {
		background-color: lightgreen;
	} */
	tr.sync-status--warning th,
	tr.sync-status--warning td {
		background-color: lightsalmon;
	}
	.item-deadlines-count-container {
		border-radius: 10px;
		width: 42px;
		/* height: 42px; */
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 5px;
		padding: 2px 4px;
		color: white;
		white-space: nowrap;
	}
</style>