import axios from "axios";
import localforage from "localforage";
import { currentDatetime } from "@/utils/datetime-utils";

export default class ItemCreationOperationsRepository {

	constructor () {
		this.repositoryNamespace = "ITEM_CREATION_OPERATIONS"
	}

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(itemCreationOperations => itemCreationOperations ?? [])
	}

	async create (itemCreationOperationData) {
		let itemCreationOperation = {
			uuid:                 itemCreationOperationData.uuid,
			registration_number:  itemCreationOperationData.registration_number,
			location:             itemCreationOperationData.location,
			notes:                itemCreationOperationData.notes,
			registry_id:          itemCreationOperationData.registry_id,
			registry_location_id: itemCreationOperationData.registry_location_id,
			object_type_id:       itemCreationOperationData.object_type_id,
			object_features_ids:  itemCreationOperationData.object_features_ids,
			fire_class_id:        itemCreationOperationData.fire_class_id,
			date:                 itemCreationOperationData.date,
			last_revision:        itemCreationOperationData.last_revision,
			last_testing:         itemCreationOperationData.last_testing,
			last_control:         itemCreationOperationData.last_control,
			quantity:             itemCreationOperationData.quantity,
			measure_unit:         itemCreationOperationData.measure_unit,
			length:               itemCreationOperationData.length,
			synced_at:            null,
			created_at:           currentDatetime(),
		}

		if (navigator.onLine) {
			let requestParams = {
				uuid:                 itemCreationOperationData.uuid,
				registration_number:  itemCreationOperationData.registration_number,
				location:             itemCreationOperationData.location,
				notes:                itemCreationOperationData.notes,
				registry_id:          itemCreationOperationData.registry_id,
				registry_location_id: itemCreationOperationData.registry_location_id,
				object_type_id:       itemCreationOperationData.object_type_id,
				object_features_ids:  itemCreationOperationData.object_features_ids,
				fire_class_id:        itemCreationOperationData.fire_class_id,
				date:                 itemCreationOperationData.date,
				last_revision:        itemCreationOperationData.last_revision,
				last_testing:         itemCreationOperationData.last_testing,
				last_control:         itemCreationOperationData.last_control,
				quantity:             itemCreationOperationData.quantity,
				measure_unit:         itemCreationOperationData.measure_unit,
				length:               itemCreationOperationData.length,
			}

			try {
				await axios.post("/item-creation-operations/create", requestParams)
				itemCreationOperation.synced_at = currentDatetime()
			} catch (error) {
				// Some errors are handled with an interceptor
			}
		}

		let existingItemCreationOperations = await localforage.getItem(this.repositoryNamespace) ?? []
		existingItemCreationOperations.push(itemCreationOperation)

		return localforage.setItem(this.repositoryNamespace, existingItemCreationOperations)
	}

	async syncAll () {
		let existingItemCreationOperations = await localforage.getItem(this.repositoryNamespace) ?? []

		for (let i = 0; i < existingItemCreationOperations.length; i++) {
			let itemCreationOperation = existingItemCreationOperations[i]

			if (navigator.onLine && itemCreationOperation.synced_at == null) {
				let requestParams = {
					uuid:                 itemCreationOperation.uuid,
					registration_number:  itemCreationOperation.registration_number,
					location:             itemCreationOperation.location,
					notes:                itemCreationOperation.notes,
					registry_id:          itemCreationOperation.registry_id,
					registry_location_id: itemCreationOperation.registry_location_id,
					object_type_id:       itemCreationOperation.object_type_id,
					object_features_ids:  itemCreationOperation.object_features_ids,
					fire_class_id:        itemCreationOperation.fire_class_id,
					date:                 itemCreationOperation.date,
					last_revision:        itemCreationOperation.last_revision,
					last_testing:         itemCreationOperation.last_testing,
					last_control:         itemCreationOperation.last_control,
					quantity:             itemCreationOperation.quantity,
					measure_unit:         itemCreationOperation.measure_unit,
					length:               itemCreationOperation.length,
				}

				try {
					await axios.post("/item-creation-operations/create", requestParams)
					existingItemCreationOperations[i].synced_at = currentDatetime()
				} catch (error) {
					// Some errors are handled with an interceptor
				}
			}
		}

		return localforage.setItem(this.repositoryNamespace, existingItemCreationOperations)
	}

	// --------------------------------------------------

	async deleteAll () {
		return localforage.setItem(this.repositoryNamespace, [])
	}
}