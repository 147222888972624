<template>
	<div id="items-info-by-uuid-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Informazioni oggetto
			</h1>
		</div>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="card mb-3">
				<div class="card-body">
					<p class="card-text">
						Codice scansionato: {{ scannedCode }}
					</p>
				</div>
			</div>

			<button class="btn w-100 mb-3" :class="scannedCode ? 'btn-success' : 'btn-primary'" type="button" @click="startQrScanner()" v-show="!showQrScanner">
				Scansiona codice
			</button>

			<div class="card mb-3" v-show="showQrScanner">
				<div class="card-body">
					<h4 class="card-title">
						Scansiona codice
					</h4>
					<canvas id="qr-scanner-canvas" style="border: solid 1px black; width: 100%;"></canvas>
				</div>
			</div>

			<div class="card mb-3" v-if="scannedCode && selectedItem == null">
				<div class="card-body">
					<p class="card-text">
						Nessun oggetto trovato.
					</p>
				</div>
			</div>

			<div class="card mb-3" v-if="scannedCode && selectedItem">
				<div class="card-body">
					<h4 class="card-title mb-0">
						{{ selectedItem.registry.name }}
						<br>
						<small class="text-muted">
							{{ selectedItem.registry_location.address }}
							-
							{{ selectedItem.registry_location.zip }}
							{{ selectedItem.registry_location.city }}
							({{ selectedItem.registry_location.province_code }})
						</small>
					</h4>
				</div>
				<div class="card-body">
					<div class="form-group mb-3">
						<label class="form-label">Tipologia</label>
						<span class="form-control">{{ selectedItem.object_type.name }}</span>
					</div>

					<div class="form-group mb-3">
						<label class="form-label">Ubicazione</label>
						<input type="text" class="form-control" v-model="item.location">
					</div>

					<div class="form-group mb-3">
						<label class="form-label">Matricola</label>
						<input type="text" class="form-control" v-model="item.registration_number">
					</div>

					<div class="form-group mb-3" v-if="availableObjectTypeFeatures.length > 0">
						<label class="form-label">Caratteristiche</label>
						<select class="form-select" v-model="item.object_features_ids" multiple>
							<option v-for="objectFeature in availableObjectTypeFeatures" :key="objectFeature.id" :value="objectFeature.id">{{ objectFeature.name }}</option>
						</select>
					</div>

					<div class="form-group mb-3" v-if="selectedItem && selectedItem.object_type.name == 'Estintore'">
						<label class="form-label">Classe d'incendio</label>
						<select class="form-select" v-model="item.fire_class_id" :disabled="fireClasses.length == 0">
							<option v-for="fireClass in fireClasses" :key="fireClass.id" :value="fireClass.id">{{ fireClass.code }}</option>
						</select>
					</div>

					<div class="form-group mb-3" v-if="selectedItem && ['Estintore', 'Colonna idrante soprasuolo', 'Colonna idrante sottosuolo', 'Manichetta Idrante'].includes(selectedItem.object_type.name)">
						<label class="form-label">Anno</label>
						<input type="text" class="form-control" v-model="item.date">
					</div>

					<div class="form-group mb-3" v-if="selectedItem && selectedItem.object_type.name == 'Estintore'">
						<label class="control-label">Ultima revisione</label>
						<input type="date" class="form-control" v-model="item.last_revision">
					</div>

					<div class="form-group mb-3" v-if="selectedItem && ['Estintore', 'Manichetta Idrante'].includes(selectedItem.object_type.name)">
						<label class="control-label">Ultimo collaudo</label>
						<input type="date" class="form-control" v-model="item.last_testing">
					</div>

					<div class="form-group mb-3">
						<label class="control-label">Ultimo controllo</label>
						<input type="date" class="form-control" v-model="item.last_control">
					</div>

					<div class="form-group mb-3">
						<label class="form-label">Note</label>
						<textarea class="form-control" v-model="item.notes" rows="2"></textarea>
					</div>

					<button class="btn btn-primary w-100" type="button" @click="createItemUpdateOperation()">
						Aggiorna
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import localforage from "localforage"
import jsQR from "jsqr"

import ItemsRepository from '@/repositories/items-repository'
import ItemUpdateOperationsRepository from '@/repositories/item-update-operations-repository'
import ObjectTypesRepository from '@/repositories/object-types-repository'
import FireClassesRepository from '@/repositories/fire-classes-repository'
import { formatDate } from '@/utils/datetime-utils'

let itemsRepository = new ItemsRepository()
let itemUpdateOperationsRepository = new ItemUpdateOperationsRepository()
let objectTypesRepository = new ObjectTypesRepository()
let fireClassesRepository = new FireClassesRepository()

let itemsList = [] // Initialized here since we do not need it to be reactive

export default {
	name: "LabelReplacementWithQrCodeView",
	data () {
		return {
			loading: false,

			showQrScanner: false,
			scannedCode: "",
			objectFeatures: [],
			selectedItem: null,
			item: {
				uuid: "",
				registration_number: "",
				location: "",
				notes: "",
				object_features_ids: [],
				fire_class_id: null,
				date: null,
				last_revision: null,
				last_testing: null,
				last_control: null,
			},
			qrVideo: null,
			qrCanvasElement: null,
			qrCanvas: null,
		}
	},
	computed: {
		availableObjectTypeFeatures () {
			if (this.selectedItem == null) {
				return []
			}

			return this.objectTypes.find(r => r.id == this.selectedItem.object_type_id).object_features
		},
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			itemsRepository.getAll().then(items => itemsList = items),
			objectTypesRepository.getAll().then(objectTypes => this.objectTypes = objectTypes),
			fireClassesRepository.getAll().then(fireClasses => this.fireClasses = fireClasses),
		]).finally(() => this.loading = false)
	},
	beforeDestroy () {
		this.stopQrScanner()
	},
	methods: {
		formatDate: formatDate,
		findItemByUuid (uuid) {
			return itemsList.find(item => item.uuid == uuid)
		},
		startQrScanner () {
			this.showQrScanner = true

			this.qrVideo = document.createElement("video")
			this.qrCanvasElement = document.getElementById("qr-scanner-canvas")
			this.qrCanvas = this.qrCanvasElement.getContext("2d")

			navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(stream => {
				this.qrVideo.srcObject = stream
				this.qrVideo.setAttribute("playsinline", true) // Required to tell iOS safari we don't want fullscreen
				this.qrVideo.play()
				requestAnimationFrame(this.qrScannerCanvasCallback)
			})
		},
		stopQrScanner () {
			if (this.qrVideo && this.qrVideo.srcObject) {
				this.qrVideo.srcObject.getTracks().forEach(track => track.stop())
				this.qrVideo.srcObject = null
			}

			this.showQrScanner = false
		},
		drawLineOnQrScannerCanvas (begin, end, color) {
			this.qrCanvas.beginPath();
			this.qrCanvas.moveTo(begin.x, begin.y);
			this.qrCanvas.lineTo(end.x, end.y);
			this.qrCanvas.lineWidth = 4;
			this.qrCanvas.strokeStyle = color;
			this.qrCanvas.stroke();
		},
		qrScannerCanvasCallback () {
			if (this.qrVideo && this.qrVideo.readyState == this.qrVideo.HAVE_ENOUGH_DATA) {
				this.qrCanvasElement.height = this.qrVideo.videoHeight;
				this.qrCanvasElement.width = this.qrVideo.videoWidth;
				this.qrCanvas.drawImage(this.qrVideo, 0, 0, this.qrCanvasElement.width, this.qrCanvasElement.height);

				let imageData = this.qrCanvas.getImageData(0, 0, this.qrCanvasElement.width, this.qrCanvasElement.height)
				let scannedCode = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })

				if (scannedCode) {
					this.drawLineOnQrScannerCanvas(scannedCode.location.topLeftCorner, scannedCode.location.topRightCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.topRightCorner, scannedCode.location.bottomRightCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.bottomRightCorner, scannedCode.location.bottomLeftCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.bottomLeftCorner, scannedCode.location.topLeftCorner, "#FF3B58");

					this.scannedCode = scannedCode.data
					this.selectedItem = this.findItemByUuid(scannedCode.data)

					this.item.uuid = this.selectedItem.uuid
					this.item.registration_number = this.selectedItem.registration_number
					this.item.location = this.selectedItem.location
					this.item.object_features_ids = this.selectedItem.object_features.map(object_feature => object_feature.id)
					this.item.fire_class_id = this.selectedItem.fire_class_id
					this.item.date = this.selectedItem.date
					this.item.notes = this.selectedItem.notes
					this.item.last_revision = this.selectedItem.latest_revision
					this.item.last_testing = this.selectedItem.latest_testing
					this.item.last_control = this.selectedItem.latest_control

					this.stopQrScanner()
				}
			}

			requestAnimationFrame(this.qrScannerCanvasCallback)
		},
		createItemUpdateOperation () {
			this.loading = true

			itemUpdateOperationsRepository.create(this.item)
				.finally(() => {
					this.loading = false
					this.$router.push({name: 'home'})
				})
		},
	}
}
</script>