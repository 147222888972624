import axios from 'axios'
import localforage from "localforage";

export default class ItemsRepository {

	constructor () {
		this.repositoryNamespace = "ITEMS"
	}

	// --------------------------------------------------

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(items => items ?? [])
	}

	async replaceAll (items) {
		return localforage.setItem(this.repositoryNamespace, items)
	}

	async replaceAllFromRemote () {
		try {
			let items = await axios.get("/items").then(response => response.data)
			return this.replaceAll(items)
		} catch (error) {
			// Some errors are handled with an interceptor
		}

		return this.getAll()
	}

	// --------------------------------------------------

	async deleteAll () {
		return localforage.setItem(this.repositoryNamespace, [])
	}
}