<template>
	<div id="items-create-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Inserimento oggetto
			</h1>
		</div>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="card mb-3">
				<div class="card-body">
					<div class="form-group mb-3">
						<label class="form-label">Sede <span class="text-red">*</span></label>
						<select class="form-select" v-model="item.registry_location_id" :disabled="availableRegistryLocations.length == 0">
							<option v-for="registryLocation in availableRegistryLocations" :key="registryLocation.id" :value="registryLocation.id">
								{{ registryLocation.name }}
								-
								{{ registryLocation.address }}
								-
								{{ registryLocation.zip }}
								{{ registryLocation.city }}
								({{ registryLocation.province_code }})
							</option>
						</select>
					</div>

					<div class="form-group mb-3">
						<label class="form-label">Tipologia <span class="text-red">*</span></label>
						<select class="form-select" v-model="item.object_type_id">
							<option v-for="objectType in objectTypes" :key="objectType.id" :value="objectType.id">{{ objectType.name }}</option>
						</select>
					</div>

					<div class="form-group mb-3" v-if="availableObjectTypeFeatures.length > 0">
						<label class="form-label">Caratteristiche</label>
						<select class="form-select" v-model="item.object_features_ids" multiple>
							<option v-for="objectFeature in availableObjectTypeFeatures" :key="objectFeature.id" :value="objectFeature.id">{{ objectFeature.name }}</option>
						</select>
					</div>

					<div class="form-group mb-3" v-if="selectedObjectType && selectedObjectType.name == 'Estintore'">
						<label class="form-label">Classe d'incendio</label>
						<select class="form-select" v-model="item.fire_class_id" :disabled="fireClasses.length == 0">
							<option v-for="fireClass in fireClasses" :key="fireClass.id" :value="fireClass.id">{{ fireClass.code }}</option>
						</select>
					</div>

					<div class="form-group mb-3">
						<label class="form-label">Ubicazione</label>
						<input type="text" class="form-control" v-model="item.location">
					</div>

					<div class="form-group mb-3" v-if="selectedObjectType && ['Estintore', 'Colonna idrante soprasuolo', 'Colonna idrante sottosuolo', 'Manichetta Idrante', 'PORTA REI', 'Portone Tagliafuoco', 'Serranda tagliafuoco', 'U.EMERGENZA'].includes(selectedObjectType.name)">
						<label class="form-label">Matricola</label>
						<input type="text" class="form-control" v-model="item.registration_number">
					</div>

					<div class="form-group mb-3" v-if="selectedObjectType && ['Estintore', 'Colonna idrante soprasuolo', 'Colonna idrante sottosuolo', 'Manichetta Idrante'].includes(selectedObjectType.name)">
						<label class="form-label">Anno</label>
						<input type="text" class="form-control" v-model="item.date">
					</div>

					<div class="form-group mb-3" v-if="selectedObjectType && ['LAMPADA EM.', 'Estintore'].includes(selectedObjectType.name)">
						<label class="form-label">Quantità</label>
						<input type="text" class="form-control" v-model="item.quantity">
					</div>

					<div class="form-group mb-3" v-if="selectedObjectType && ['LAMPADA EM.', 'Estintore'].includes(selectedObjectType.name)">
						<label class="form-label">Unità di misura</label>
						<select class="form-select" v-model="item.measure_unit">
							<option value="qty" v-if="selectedObjectType && selectedObjectType.name == 'LAMPADA EM.'">pezzi</option>
							<option value="l" v-if="selectedObjectType && selectedObjectType.name == 'Estintore'">litri</option>
							<option value="kg" v-if="selectedObjectType && selectedObjectType.name == 'Estintore'">kilogrammi</option>
						</select>
					</div>

					<div class="form-group mb-3" v-if="selectedObjectType && selectedObjectType.name == 'Manichetta Idrante'">
						<label class="form-label">Lunghezza</label>
						<select class="form-select" v-model="item.length">
							<option value="15">15 m</option>
							<option value="20">20 m</option>
							<option value="25">25 m</option>
						</select>
					</div>

					<div class="form-group mb-3" v-if="selectedObjectType && selectedObjectType.name == 'Estintore'">
						<label class="control-label">Ultima revisione</label>
						<input type="date" class="form-control" v-model="item.last_revision">
					</div>

					<div class="form-group mb-3" v-if="selectedObjectType && ['Estintore', 'Manichetta Idrante'].includes(selectedObjectType.name)">
						<label class="control-label">Ultimo collaudo</label>
						<input type="date" class="form-control" v-model="item.last_testing">
					</div>

					<div class="form-group mb-3">
						<label class="control-label">Ultimo controllo</label>
						<input type="date" class="form-control" v-model="item.last_control">
					</div>

					<div class="form-group mb-3">
						<label class="form-label">Note</label>
						<textarea class="form-control" v-model="item.notes" rows="2"></textarea>
					</div>

					<div class="form-group mb-3">
						<label class="form-label">Codice</label>

						<button class="btn w-100" :class="item.uuid ? 'btn-success' : 'btn-primary'" type="button" @click="startQrScanner()" v-show="!showQrScanner">
							{{ item.uuid ? item.uuid : "Scansiona codice" }}
						</button>

						<canvas id="new-qr-scanner-canvas" v-show="showQrScanner" style="border: solid 1px black; width: 100%;"></canvas>

						<button class="btn btn-secondary w-100" type="button" @click="stopQrScanner()" v-show="showQrScanner">
							Annulla
						</button>
					</div>

					<button class="btn btn-primary w-100" type="button" @click="createItem()" :disabled="item.registry_id == null || item.registry_location_id == null || item.object_type_id == null || item.uuid == ''">
						Inserisci
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import localforage from "localforage"
import jsQR from "jsqr"
import { firstDayOfTheMonth } from '@/utils/datetime-utils'

import ItemCreationOperationsRepository from '@/repositories/item-creation-operations-repository'
import RegistriesRepository from '@/repositories/registries-repository'
import ObjectFeaturesRepository from '@/repositories/object-features-repository'
import ObjectTypesRepository from '@/repositories/object-types-repository'
import FireClassesRepository from '@/repositories/fire-classes-repository'

let itemCreationOperationsRepository = new ItemCreationOperationsRepository()
let registriesRepository = new RegistriesRepository()
let objectFeaturesRepository = new ObjectFeaturesRepository()
let objectTypesRepository = new ObjectTypesRepository()
let fireClassesRepository = new FireClassesRepository()

export default {
	name: "ItemsCreateView",
	data () {
		return {
			loading: false,
			registries: [],
			objectFeatures: [],
			objectTypes: [],
			fireClasses: [],

			item: {
				uuid: "",
				registration_number: "",
				location: "",
				notes: "",
				registry_id: null,
				registry_location_id: null,
				object_type_id: null,
				object_features_ids: [],
				fire_class_id: null,
				date: null,
				last_revision: null,
				last_testing: null,
				last_control: firstDayOfTheMonth(),
				quantity: 0,
				measure_unit: "",
				length: 0,
			},

			showQrScanner: false,
			qrVideo: null,
			qrCanvasElement: null,
			qrCanvas: null,
		}
	},
	computed: {
		availableRegistries () {
			return this.registries
		},
		selectedRegistry () {
			if (!this.item.registry_id) {
				return null
			}

			return this.availableRegistries.find(r => r.id == this.item.registry_id)
		},
		availableRegistryLocations () {
			return this.registries.map(r => r.registry_locations).flat()
		},
		selectedRegistryLocation () {
			if (!this.item.registry_location_id) {
				return null
			}

			return this.availableRegistryLocations.find(rl => rl.id == this.item.registry_location_id)
		},
		selectedObjectType () {
			if (!this.item.object_type_id) {
				return null
			}

			return this.objectTypes.find(r => r.id == this.item.object_type_id)
		},
		availableObjectTypeFeatures () {
			if (this.selectedObjectType == null) {
				return []
			}

			return this.selectedObjectType.object_features
		},
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			registriesRepository.getAllForAssignedItemDeadlines().then(registries => this.registries = registries),
			objectFeaturesRepository.getAll().then(objectFeatures => this.objectFeatures = objectFeatures),
			objectTypesRepository.getAll().then(objectTypes => this.objectTypes = objectTypes),
			fireClassesRepository.getAll().then(fireClasses => this.fireClasses = fireClasses),
		]).finally(() => {
			if (this.availableRegistryLocations.length == 1) {
				this.item.registry_location_id = this.availableRegistryLocations[0].id
			}

			this.loading = false
		})
	},
	beforeDestroy () {
		this.stopQrScanner()
	},
	methods: {
		startQrScanner () {
			this.showQrScanner = true

			this.newVideo = document.createElement("video")
			this.newCanvasElement = document.getElementById("new-qr-scanner-canvas")
			this.newCanvas = this.newCanvasElement.getContext("2d")

			navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(stream => {
				this.newVideo.srcObject = stream
				this.newVideo.setAttribute("playsinline", true) // Required to tell iOS safari we don't want fullscreen
				this.newVideo.play()
				requestAnimationFrame(this.qrScannerCanvasCallback)
			})
		},
		stopQrScanner () {
			if (this.newVideo && this.newVideo.srcObject) {
				this.newVideo.srcObject.getTracks().forEach(track => track.stop())
				this.newVideo.srcObject = null
			}

			this.showQrScanner = false
		},
		drawLineOnQrScannerCanvas (begin, end, color) {
			this.newCanvas.beginPath();
			this.newCanvas.moveTo(begin.x, begin.y);
			this.newCanvas.lineTo(end.x, end.y);
			this.newCanvas.lineWidth = 4;
			this.newCanvas.strokeStyle = color;
			this.newCanvas.stroke();
		},
		qrScannerCanvasCallback () {
			if (this.newVideo && this.newVideo.readyState == this.newVideo.HAVE_ENOUGH_DATA) {
				this.newCanvasElement.height = this.newVideo.videoHeight;
				this.newCanvasElement.width = this.newVideo.videoWidth;
				this.newCanvas.drawImage(this.newVideo, 0, 0, this.newCanvasElement.width, this.newCanvasElement.height);

				let imageData = this.newCanvas.getImageData(0, 0, this.newCanvasElement.width, this.newCanvasElement.height)
				let scannedCode = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })

				if (scannedCode) {
					this.drawLineOnQrScannerCanvas(scannedCode.location.topLeftCorner, scannedCode.location.topRightCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.topRightCorner, scannedCode.location.bottomRightCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.bottomRightCorner, scannedCode.location.bottomLeftCorner, "#FF3B58");
					this.drawLineOnQrScannerCanvas(scannedCode.location.bottomLeftCorner, scannedCode.location.topLeftCorner, "#FF3B58");

					this.item.uuid = scannedCode.data

					this.stopQrScanner()
				}
			}

			requestAnimationFrame(this.qrScannerCanvasCallback)
		},
		createItem () {
			this.loading = true

			itemCreationOperationsRepository.create(this.item)
				.finally(() => {
					this.loading = false
					this.$router.push({name: 'home'})
				})
		},
	},
	watch: {
		selectedRegistryLocation (newValue) {
			this.item.registry_id = newValue.registry_id
		},
		selectedObjectType (newValue) {
			this.item.object_features_ids = this.item.object_features_ids.splice(0)

			if (['Attacco VVF', 'Colonna idrante soprasuolo', 'Colonna idrante sottosuolo', 'Manichetta Idrante', 'EFC', 'G. Pompaggio', 'Impianto Sprinkler', 'Impianto rilevazione fumi', 'PORTA REI', 'Portone Tagliafuoco', 'Serranda tagliafuoco'].includes(newValue.name)) {
				this.item.quantity = 1
				this.item.measure_unit = 'qty'
			} else if (['Estintore'].includes(newValue.name)) {
				this.item.quantity = 6
				this.item.measure_unit = 'kg'
			} else {
				this.item.quantity = 0
				this.item.measure_unit = ''
			}
		},
	}
}
</script>