<template>
	<div id="label-replacement-index-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Sostituzione etichetta
			</h1>
		</div>

		<div class="card mb-3">
			<div class="card-body">
				<p class="card-text">Il codice QR sull'etichetta è leggibile?</p>

				<div class="row">
					<div class="col">
						<router-link class="btn btn-success w-100" :to="{name: 'label-replacement.with-qr-code'}">
							Sì
						</router-link>
					</div>
					<div class="col">
						<router-link class="btn btn-danger w-100" :to="{name: 'label-replacement.without-qr-code'}">
							No
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LabelReplacementIndexView",
	data () {
		return {

		}
	},
	computed: {

	},
	mounted () {
	},
	methods: {
	}
}
</script>