<template>
	<div id="label-replacement-with-qr-code-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Sostituzione etichetta
			</h1>
		</div>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="card mb-3">
				<div class="card-body">
					<p class="card-text">
						Codice vecchio: {{ oldScannedCode }}
					</p>
					<p class="card-text">
						Codice nuovo: {{ newScannedCode }}
					</p>
				</div>
			</div>

			<button class="btn w-100 mb-3" :class="oldScannedCode ? 'btn-success' : 'btn-primary'" type="button" @click="startOldQrScanner()" v-show="!showOldQrScanner">
				Scansiona codice vecchio
			</button>

			<div class="card mb-3" v-show="showOldQrScanner">
				<div class="card-body">
					<h4 class="card-title">
						Scansiona codice vecchio
					</h4>
					<canvas id="old-qr-scanner-canvas" style="border: solid 1px black; width: 100%;"></canvas>
				</div>
			</div>

			<button class="btn w-100 mb-3" :class="newScannedCode ? 'btn-success' : 'btn-primary'" type="button" @click="startNewQrScanner()" v-show="!showNewQrScanner">
				Scansiona codice nuovo
			</button>

			<div class="card mb-3" v-show="showNewQrScanner">
				<div class="card-body">
					<h4 class="card-title">
						Scansiona codice nuovo
					</h4>
					<canvas id="new-qr-scanner-canvas" style="border: solid 1px black; width: 100%;"></canvas>
				</div>
			</div>

			<button class="btn btn-primary w-100 mb-3" type="button" @click="confirmLabelReplacement()" :disabled="!(oldScannedCode && newScannedCode)">
				Conferma
			</button>
		</div>
	</div>
</template>

<script>
import localforage from "localforage"
import jsQR from "jsqr"

import ItemsRepository from '@/repositories/items-repository'
import LabelReplacementOperationsRepository from '@/repositories/label-replacement-operations-repository'

let itemsRepository = new ItemsRepository()
let labelReplacementOperationsRepository = new LabelReplacementOperationsRepository()

let itemsList = [] // Initialized here since we do not need it to be reactive

export default {
	name: "LabelReplacementWithQrCodeView",
	data () {
		return {
			loading: false,

			showOldQrScanner: false,
			oldScannedCode: "",
			oldSelectedItem: null,
			oldQrVideo: null,
			oldQrCanvasElement: null,
			oldQrCanvas: null,

			showNewQrScanner: false,
			newScannedCode: "",
			newSelectedItem: null,
			newQrVideo: null,
			newQrCanvasElement: null,
			newQrCanvas: null,
		}
	},
	computed: {

	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			itemsRepository.getAll().then(items => itemsList = items),
		]).finally(() => this.loading = false)
	},
	beforeDestroy () {
		this.stopOldQrScanner()
		this.stopNewQrScanner()
	},
	methods: {
		findItemByUuid (uuid) {
			return itemsList.find(item => item.uuid == uuid)
		},
		startOldQrScanner () {
			this.showOldQrScanner = true

			this.oldQrVideo = document.createElement("video")
			this.oldQrCanvasElement = document.getElementById("old-qr-scanner-canvas")
			this.oldQrCanvas = this.oldQrCanvasElement.getContext("2d")

			navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(stream => {
				this.oldQrVideo.srcObject = stream
				this.oldQrVideo.setAttribute("playsinline", true) // Required to tell iOS safari we don't want fullscreen
				this.oldQrVideo.play()
				requestAnimationFrame(this.oldQrScannerCanvasCallback)
			})
		},
		stopOldQrScanner () {
			if (this.oldQrVideo && this.oldQrVideo.srcObject) {
				this.oldQrVideo.srcObject.getTracks().forEach(track => track.stop())
				this.oldQrVideo.srcObject = null
			}

			this.showOldQrScanner = false
		},
		drawLineOnOldQrScannerCanvas (begin, end, color) {
			this.oldQrCanvas.beginPath();
			this.oldQrCanvas.moveTo(begin.x, begin.y);
			this.oldQrCanvas.lineTo(end.x, end.y);
			this.oldQrCanvas.lineWidth = 4;
			this.oldQrCanvas.strokeStyle = color;
			this.oldQrCanvas.stroke();
		},
		oldQrScannerCanvasCallback () {
			if (this.oldQrVideo && this.oldQrVideo.readyState == this.oldQrVideo.HAVE_ENOUGH_DATA) {
				this.oldQrCanvasElement.height = this.oldQrVideo.videoHeight;
				this.oldQrCanvasElement.width = this.oldQrVideo.videoWidth;
				this.oldQrCanvas.drawImage(this.oldQrVideo, 0, 0, this.oldQrCanvasElement.width, this.oldQrCanvasElement.height);

				let imageData = this.oldQrCanvas.getImageData(0, 0, this.oldQrCanvasElement.width, this.oldQrCanvasElement.height)
				let scannedCode = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })

				if (scannedCode) {
					this.drawLineOnOldQrScannerCanvas(scannedCode.location.topLeftCorner, scannedCode.location.topRightCorner, "#FF3B58");
					this.drawLineOnOldQrScannerCanvas(scannedCode.location.topRightCorner, scannedCode.location.bottomRightCorner, "#FF3B58");
					this.drawLineOnOldQrScannerCanvas(scannedCode.location.bottomRightCorner, scannedCode.location.bottomLeftCorner, "#FF3B58");
					this.drawLineOnOldQrScannerCanvas(scannedCode.location.bottomLeftCorner, scannedCode.location.topLeftCorner, "#FF3B58");

					this.oldScannedCode = scannedCode.data
					this.oldSelectedItem = this.findItemByUuid(scannedCode.data)

					this.stopOldQrScanner()
				}
			}

			requestAnimationFrame(this.oldQrScannerCanvasCallback)
		},
		startNewQrScanner () {
			this.showNewQrScanner = true

			this.newQrVideo = document.createElement("video")
			this.newQrCanvasElement = document.getElementById("new-qr-scanner-canvas")
			this.newQrCanvas = this.newQrCanvasElement.getContext("2d")

			navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(stream => {
				this.newQrVideo.srcObject = stream
				this.newQrVideo.setAttribute("playsinline", true) // Required to tell iOS safari we don't want fullscreen
				this.newQrVideo.play()
				requestAnimationFrame(this.newQrScannerCanvasCallback)
			})
		},
		stopNewQrScanner () {
			if (this.newQrVideo && this.newQrVideo.srcObject) {
				this.newQrVideo.srcObject.getTracks().forEach(track => track.stop())
				this.newQrVideo.srcObject = null
			}

			this.showNewQrScanner = false
		},
		drawLineOnNewQrScannerCanvas (begin, end, color) {
			this.newQrCanvas.beginPath();
			this.newQrCanvas.moveTo(begin.x, begin.y);
			this.newQrCanvas.lineTo(end.x, end.y);
			this.newQrCanvas.lineWidth = 4;
			this.newQrCanvas.strokeStyle = color;
			this.newQrCanvas.stroke();
		},
		newQrScannerCanvasCallback () {
			if (this.newQrVideo && this.newQrVideo.readyState == this.newQrVideo.HAVE_ENOUGH_DATA) {
				this.newQrCanvasElement.height = this.newQrVideo.videoHeight;
				this.newQrCanvasElement.width = this.newQrVideo.videoWidth;
				this.newQrCanvas.drawImage(this.newQrVideo, 0, 0, this.newQrCanvasElement.width, this.newQrCanvasElement.height);

				let imageData = this.newQrCanvas.getImageData(0, 0, this.newQrCanvasElement.width, this.newQrCanvasElement.height)
				let scannedCode = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })

				if (scannedCode) {
					this.drawLineOnNewQrScannerCanvas(scannedCode.location.topLeftCorner, scannedCode.location.topRightCorner, "#FF3B58");
					this.drawLineOnNewQrScannerCanvas(scannedCode.location.topRightCorner, scannedCode.location.bottomRightCorner, "#FF3B58");
					this.drawLineOnNewQrScannerCanvas(scannedCode.location.bottomRightCorner, scannedCode.location.bottomLeftCorner, "#FF3B58");
					this.drawLineOnNewQrScannerCanvas(scannedCode.location.bottomLeftCorner, scannedCode.location.topLeftCorner, "#FF3B58");

					this.newScannedCode = scannedCode.data
					this.newSelectedItem = this.findItemByUuid(scannedCode.data)

					this.stopNewQrScanner()
				}
			}

			requestAnimationFrame(this.newQrScannerCanvasCallback)
		},
		confirmLabelReplacement () {
			this.loading = true

			labelReplacementOperationsRepository.create({old_uuid: this.oldScannedCode, new_uuid: this.newScannedCode})
				.finally(() => {
					this.loading = false
					this.$router.push({name: 'home'})
				})
		},
	}
}
</script>