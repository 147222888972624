import axios from "axios";
import localforage from "localforage";

export default class DeadlinesByRegistryRepository {

	constructor () {
		this.repositoryNamespace = "DEADLINES_BY_REGISTRY"
	}

	async getByDate (date) {
		let storageKey = this.repositoryNamespace + "__" + date

		if (navigator.onLine) {
			try {
				let deadlines = await axios.get("/item-deadlines/deadlines-by-registry", {params: {date: date}}).then(response => response.data)
				return localforage.setItem(storageKey, deadlines ?? [])
			} catch (error) {
				// Some errors are handled with an interceptor
			}
		}

		return localforage.getItem(storageKey).then(deadlines => deadlines ?? [])
	}

	// --------------------------------------------------

	async deleteAll () {
		let keys = await localforage.keysStartingWith(this.repositoryNamespace)

		return Promise.all(keys.map(key => localforage.removeItem(key)))
	}
}