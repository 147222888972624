import axios from "axios";
import localforage from "localforage";
import { currentDatetime } from "@/utils/datetime-utils";

export default class MaintenancesRepository {

	constructor () {
		this.repositoryNamespace = "MAINTENANCES"
	}

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(maintenances => maintenances ?? [])
	}

	async create (registry, registryLocation, item, itemDeadline) {
		let maintenance = {
			registry: registry,
			registryLocation: registryLocation,
			item: item,
			itemDeadline: itemDeadline,
			synced_at: null,
		}


		if (navigator.onLine) {
			let requestParams = {
				item_deadline_id: maintenance.itemDeadline.id,
				completed_at: maintenance.itemDeadline.completed_at,
				maintainer_id: maintenance.itemDeadline.maintainer_id,
			}

			try {
				await axios.post("/item-deadlines/record-maintenance", requestParams)
				maintenance.synced_at = currentDatetime()
			} catch (error) {
				// Some errors are handled with an interceptor
			}
		}

		let existingMaintenances = await localforage.getItem(this.repositoryNamespace) ?? []
		existingMaintenances.push(maintenance)

		return localforage.setItem(this.repositoryNamespace, existingMaintenances)
	}

	async syncAll () {
		let existingMaintenances = await localforage.getItem(this.repositoryNamespace) ?? []

		for (let i = 0; i < existingMaintenances.length; i++) {
			let maintenance = existingMaintenances[i]

			if (navigator.onLine && maintenance.synced_at == null) {
				let requestParams = {
					item_deadline_id: maintenance.itemDeadline.id,
					completed_at: maintenance.itemDeadline.completed_at,
					maintainer_id: maintenance.itemDeadline.maintainer_id,
				}

				try {
					await axios.post("/item-deadlines/record-maintenance", requestParams)
					existingMaintenances[i].synced_at = currentDatetime()
				} catch (error) {
					// Some errors are handled with an interceptor
				}
			}
		}

		return localforage.setItem(this.repositoryNamespace, existingMaintenances)
	}

	// --------------------------------------------------

	async deleteAll () {
		return localforage.setItem(this.repositoryNamespace, [])
	}
}