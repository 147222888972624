import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import ItemDeadlinesIndexView from '@/views/ItemDeadlinesIndexView.vue'
import MaintenancesIndexView from '@/views/MaintenancesIndexView.vue'
import LabelReplacementIndexView from '@/views/LabelReplacementIndexView.vue'
import LabelReplacementWithQrCodeView from '@/views/LabelReplacementWithQrCodeView.vue'
import LabelReplacementWithoutQrCodeView from '@/views/LabelReplacementWithoutQrCodeView.vue'
import AnnotationsIndexView from '@/views/AnnotationsIndexView.vue'
import AnnotationsCreateView from '@/views/AnnotationsCreateView.vue'
import ItemsCreateView from '@/views/ItemsCreateView.vue'
import ItemsInfoByUuidView from '@/views/ItemsInfoByUuidView.vue'
import DataSyncView from '@/views/DataSyncView.vue'

// Import middlewares
import authMiddleware from '@/router/middlewares/auth-middleware'
import guestMiddleware from '@/router/middlewares/guest-middleware'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/item-deadlines',
		name: 'item-deadlines.index',
		component: ItemDeadlinesIndexView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/maintenances',
		name: 'maintenances.index',
		component: MaintenancesIndexView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/label-replacement',
		name: 'label-replacement.index',
		component: LabelReplacementIndexView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/label-replacement/with-qr-code',
		name: 'label-replacement.with-qr-code',
		component: LabelReplacementWithQrCodeView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/label-replacement/without-qr-code',
		name: 'label-replacement.without-qr-code',
		component: LabelReplacementWithoutQrCodeView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/annotations',
		name: 'annotations.index',
		component: AnnotationsIndexView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/annotations/create',
		name: 'annotations.create',
		component: AnnotationsCreateView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/items/create',
		name: 'items.create',
		component: ItemsCreateView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/items/info-by-uuid',
		name: 'items.info-by-uuid',
		component: ItemsInfoByUuidView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/data-sync',
		name: 'data-sync',
		component: DataSyncView,
		beforeEnter: authMiddleware,
	},
	{
		path: '/login',
		name: 'auth.login',
		component: LoginView,
		beforeEnter: guestMiddleware,
	},
	// {
	// 	path: '/about',
	// 	name: 'about',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	// }
]

const router = new VueRouter({
	routes
})

export default router
